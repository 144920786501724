import { useEffect } from "react";
import "../styles/colors.css";

export default function Tree({ scale, counts }) {
  const LEAFS_NUM = 246;
  function temp(a, b) {
    return 0.5 - Math.random();
  }
  const RANDOM_LEAFS = Array.from(Array(LEAFS_NUM).keys()).sort(temp);
  const levelCounts = counts ? counts : [0, 2, 0, 16, 6];
  const sumCounts = levelCounts.reduce((a, b) => a + b, 0);
  const leafCounts = levelCounts.map((count) => {
    return Math.ceil((count / sumCounts) * LEAFS_NUM);
  });

  useEffect(() => {
    const LEAF_COLORS = [
      "var(--black)",
      "var(--maple-green-dark)",
      "var(--maple-yellow-dark)",
      "var(--maple-orange-dark)",
      "var(--maple-red-dark)",
    ];
    let counter = 0;
    for (let i = 0; i < leafCounts.length; i++) {
      for (let j = 0; j < leafCounts[i] && counter < LEAFS_NUM; j++) {
        let leaf = document.getElementById(
          "leaf-" + (RANDOM_LEAFS[counter] + 1)
        );
        leaf.style.fill = LEAF_COLORS[i];
        counter = counter + 1;
        leaf.style.opacity = 0.95;
      }
    }
  }, [leafCounts, RANDOM_LEAFS]);

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={scale ? scale * 563 : 563}
      height={scale ? scale * 557 : 557}
      viewBox="0 0 563.07 557.97"
    >
      <g id="Trunk">
        <path
          className="st0"
          fill="var(--temple-brown)"
          d="M268.06,518.64c0.67,0,0-43.18,0-43.18l0.3-46.21l8.43-46.21v-29.55l-8.73-11.36l11.42,4.55l4.7-10.61
		l-11.42-23.49l-8.73-12.12v-17.43l-10.75-6.06l1.85-3.55l10.43,5.68l-1.44-17.24l-2.34-12.78l1.44-9.13l-1.98-8.72l-12.95,6.29
		l-14.56-1.62l-8.27-4.66l-14.38-1.42l-6.29-3.65h-7.37l-8.63,4.46l-12.59,11.56l-5.95-5.19l-8.11,0.61l-6.49,3.36l-9.19-1.22
		l-21.09,7.32l-11.63,5.8l-11.09,9.15l-7.84,3.97l9.74-10.68l6.22-5.49l-5.95-7.32l8.65,4.88l3.79-1.22l-5.41-7.32l8.92,4.58
		l14.6-5.8l9.46-3.36l10.01,0.61l10.82-2.75l8.11,1.22l7.03-4.58l10.28-5.49l1.89-4.88l3.79-3.05l0.81-7.63l-6.76-8.54l-3.79-3.97
		l12.44,9.46l2.16,8.85L200,218.4l9.46,3.97l7.3,2.75l10.55,0.61l7.03,3.05l2.7,0.92l-1.62-11.59l4.33-16.99l2.43,6.32l-1.89,8.54
		l0.81,13.12l4.87,0.92l11.36-5.19l3.25-4.58l-4.6-6.71l-9.74-8.85l-6.22-9.46l3.99-2.17l12.52,11.91l6.26,8.56l4.3,4.23h9
		l5.87,0.88l6.26,2.65l5.08,1.76v-5.29l-7.04-9.71V197.9l4.3,9.71l4.69,3.97l1.67,3.97l2.63,11.03l-4.69,2.65l-11.34-4.41
		l-9.78-1.76l-5.08,1.76l2.35,13.23l-0.39,11.03l1.96,12.35v15L271,286.57l3.52,8.38l5.08,8.38l0.78-6.62l26.6-61.76l-4.3-16.32
		l4.3-3.53l5.48,14.12l9-8.82l14.86-11.03l9.39-8.27l-5.87-19.52l1.17-10.15h4.69v10.59l3.52,14.56l4.3,2.65l9.78-4.85v-22.94
		l9.39,3.09l-6.26,0.88l0.78,10.15v6.18l13.69-3.53l11.2-1.74l9.58-0.95l0.68-7.16l-1.96-6.91l-5.7-3.38l-14.83-7.17l1.47-2.94
		l13.69,6.98l3.75,1.84l-6.52-12.13l1.3-5.88l7.33,2.94l21.83-4.59l1.3,5.51l-18.9,4.59l2.61,1.65l7.33,3.49l1.47,4.96l-14.01-8.45
		l-0.81,1.84l2.44,9.01l1.79,6.06l1.14,5.51l2.12-6.8l5.7-3.31l5.54,0.55l6.52-0.37h4.07v3.12l-5.21,0.18l-7.17-0.74l-4.56,0.55
		l-4.24,9.01l-0.98,6.25l2.77-1.47l1.96-3.86l1.47-7.17l2.12-2.02l4.4,0.55l-0.16,5.15l7.01-1.29l2.61-2.57l1.63,2.21l-2.28,3.68
		l-7.66,1.1l-3.42,0.18l-1.79,4.04l8.47-1.47l6.68-1.29l-15.48,4.96l-0.98,2.39l6.19,2.02l4.24,1.84l0.81-5.15l8.47-3.86l1.63,2.94
		l-6.84,2.76l-1.47,4.78l3.91,2.91l5.05,2.05l-1.63,3.86l-7.66-5.91l-7.98-3.65l-5.05-0.18l-0.16,3.83l7.01,10.32l-7.01,3.12
		l-6.19-11.58l-3.42-2.94l-2.61-6.98l-2.77-0.92l-6.19,1.47l-8.64,3.12l-7.5,1.47l6.84,9.37l4.24,5.96l5.54,2.68l6.03,0.92
		l10.1-2.02l-3.91,3.31l6.19,7.9l-7.98-6.62l-9.94,1.1l-10.43-5.33l-10.59-15.44l-7.66,3.86l-8.03,3.44l-7.85,3.97l-4.6,6.1
		l14.88,3.66l22.19,5.8l9.74,7.02l4.33,5.19l-15.96-8.55l-21.92-5.49l-18.13-2.14l-13.05,11.27l-16.54,22.99l-13.7,38.68
		l22.73-25.33l8.39-20.45l23.54-7.02l4.33,2.14l20.02-1.83l-0.27,7.02l-18.13,1.53l-10.01-0.61l-10.82,5.19l-4.6,9.46l-4.87,13.43
		l-7.31,9.77l-6.49,8.55l-5.41,24.72l-1.62,8.85l18.67,3.66l10.01,3.66l-30.58-0.61l4.06,21.97l-2.71,21.67l-4.6,43.03l-5.61,30.21
		l2.63,30.21l2.44,29.6l1.35,10.99l5.95,7.94l10.55,3.66l7.31,4.58h-94.44l12.18-5.8l12.18-4.58l7.31-6.41L268.06,518.64z"
        />
      </g>
      <g id="Leaves">
        <polygon
          className="st0"
          id="leaf-1"
          points="265.16,386.94 261.41,388.57 257.85,389.97 253.39,391.04 259.27,392.11 256.95,395.49 261.41,395.49 
		261.23,399.24 265.16,399.24 261.05,401.91 264.26,401.91 264.26,406.98 266.05,407.97 263.55,412.79 259.72,411.72 258.2,415.46 
		260.52,417.07 264.26,417.07 266.05,420.45 269.26,419.03 269.26,422.95 273.18,418.67 277.28,419.74 275.14,414.75 276.39,412.96 
		272.29,409.93 278.35,408.15 273.36,406.98 272.29,404.94 270.5,402.89 275.32,402.89 277.1,404.94 279.06,404.94 279.06,399.77 
		282.81,399.06 282.81,394.78 279.77,393.89 274.78,393.18 272.82,389.97 269.61,391.39 266.23,389.97 	"
        />
        <polygon
          className="st0"
          id="leaf-2"
          points="311.88,374.91 308.33,376.35 304.26,374.91 302.55,387.14 301.76,393.32 300.31,402.65 301.1,405.54 
		302.42,405.02 304.39,405.54 306.1,402.91 307.81,403.18 306.62,398.97 309.23,399.5 309.23,395.16 312.93,397.87 315.3,395.55 
		318.46,396.21 318.46,391.87 320.69,391.08 321.88,388.19 324.77,388.32 325.43,385.17 322.01,384.11 320.56,380.82 316.09,380.82 
		315.96,376.35 	"
        />
        <polygon
          className="st0"
          id="leaf-3"
          points="306.73,324.81 305.27,327.55 301.07,327.55 302.16,332.66 299.66,337.22 300.31,339.41 295.41,339.41 
		298.33,342.33 296.92,347.26 301.07,343.98 304.54,345.62 304.9,347.26 307.46,344.89 306.73,342.15 309.23,341.79 307.82,337.95 
		312.87,336.04 312.87,332.48 316.95,332.48 316.95,329.55 316.22,324.81 312.87,320.61 310.38,323.35 308.01,321.7 	"
        />
        <polygon
          className="st0"
          id="leaf-4"
          points="308.19,349.27 308.19,353.11 304.72,356.58 300.31,359.13 305.09,359.13 308.19,357.85 306.18,361.69 
		306.18,364.06 306.18,367.35 309.23,367.35 312.87,366.44 314.58,368.44 317.68,365.89 316.95,361.51 319.69,360.78 320.97,357.49 
		322.8,355.85 320.61,352.92 322.25,349.27 318.23,350.92 316.95,347.26 314.03,348.18 311.55,347.26 	"
        />
        <polygon
          className="st0"
          id="leaf-5"
          points="316.95,312.2 313.7,315.82 313.7,318.1 308.34,319.57 310.35,314.75 312.65,313.27 307.67,312.2 
		304.72,309.39 302.04,310.59 302.04,305.9 299.5,304.83 300.31,300.68 296.01,299.34 296.01,294.52 299.23,295.72 298.96,289.29 
		300.97,286.48 303.92,291.03 306.18,290.5 306.18,295.72 311.55,296.39 307.13,297.46 310.48,299.47 314.64,300.41 312.63,304.56 
		316.11,304.3 314.64,308.32 317.58,308.72 316.95,310.99 	"
        />
        <polygon
          className="st0"
          id="leaf-6"
          points="334.39,375.64 334.39,378.91 338.3,382.32 336.34,384.34 336.34,387.49 340.06,389.64 341.45,392.91 
		344.35,388.5 345.77,393.42 350.28,391.71 352.42,394.81 355.07,394.81 359.49,398.21 359.49,403.26 365.67,409.44 367.56,409.44 
		375.25,412.21 369.83,407.67 369.45,404.52 366.42,403.89 366.8,401.49 362.77,399.09 366.05,395.56 363.52,393.92 365.41,391.02 
		367.43,390.64 361.76,382.7 358.6,376.64 354.82,378.03 351.16,374.66 348.39,378.66 343.72,374.66 342.46,379.42 	"
        />
        <polygon
          className="st0"
          id="leaf-7"
          points="299.9,264.99 298.39,262.57 295.41,263.78 295.41,266.95 291.45,269.96 295.41,274.79 294.01,282.18 
		296.92,283.24 296.92,284.9 302.61,281.43 303.37,284.29 309.55,282.03 313.47,280.52 310.91,276.45 313.47,272.23 316.95,275.39 
		321.77,270.42 319.96,266.04 321.77,263.33 320.71,260.01 320.56,256.84 322.82,258.65 325.84,258.8 324.48,255.94 321.62,252.77 
		321.47,249.15 318.3,250.36 317.58,254.58 313.77,255.18 311.51,259.25 310.15,256.84 309.55,259.56 306.18,258.65 303.22,262.72 
		303.52,265.74 	"
        />
        <polygon
          className="st0"
          id="leaf-8"
          points="273.58,330.51 282.12,335.11 287.16,336.86 285.41,343.65 282.12,339.49 279.28,340.37 276.43,337.08 
		276.21,343.65 274.9,348.25 277.74,350.22 284.53,349.35 284.53,355.92 280.37,355.92 280.37,359.86 276.87,361.62 278.18,365.78 
		269.64,360.52 267.45,355.92 261.97,357.85 258.9,353.29 252.99,353.29 257.23,348.91 251.89,347.26 257.23,344.31 251.89,342.12 
		251.89,338.39 254.74,332.92 257.23,335.33 261.75,333.94 263.72,328.75 266.57,330.73 	"
        />
        <polygon
          className="st0"
          id="leaf-9"
          points="273.27,249.15 269.53,251.95 271.4,252.82 268.47,256.75 273.27,258.93 272.18,267.02 273.27,269.19 
		277.41,267.02 272.94,273.11 274.8,273.99 275.01,276.6 274.58,280.75 277.41,281.84 278.07,284.9 282.21,284.9 282.21,278.57 
		284.61,279.88 283.31,272.89 290.51,260.89 293.12,256.53 295.41,259.37 298.36,259.8 298.36,256.31 303.6,256.31 302.51,251.08 
		303.6,248.02 300.31,250.2 296.92,249.15 296.92,245.4 293.12,248.02 289.2,244.31 286.03,249.15 276.54,245.18 278.29,249.15 	"
        />
        <polygon
          className="st0"
          id="leaf-10"
          points="273.21,315.05 278.75,318.82 280.74,317.93 283.72,311.06 277.2,297.54 273.21,288.9 264.57,293.11 
		264.57,296.21 257.48,295.11 257.48,301.31 254.37,301.53 252.16,309.73 253.49,315.05 249.05,318.82 251.08,319.7 257.92,317.04 
		258.58,319.7 263.24,316.09 264.12,319.7 267.66,315.05 270.07,318.37 	"
        />
        <polygon
          className="st0"
          id="leaf-11"
          points="237.12,324.22 240.54,320.61 244.49,325.87 237.12,327.84 237.84,333.94 239.82,336.83 236.22,338.81 
		232.45,337.91 232.45,341.86 226.88,340.78 225.8,344.2 220.95,344.2 219.69,340.78 215.92,342.22 211.25,340.96 210.35,337.37 
		204.24,336.83 208.73,333.94 202.08,332.4 207.29,328.75 202.98,326.05 204.78,321.91 207.83,319.57 206.04,314.73 209.99,318.32 
		213.58,314.73 214.66,319.57 219.33,316.34 219.51,321.56 224.54,319.57 226.34,317.96 228.14,321.56 232.27,322.09 234.43,319.57 
			"
        />
        <polygon
          className="st0"
          id="leaf-12"
          points="257.08,283.56 259.84,284.36 261.87,288.81 258.38,288.81 261.87,293.81 258.38,293.81 261.87,297.52 
		256.34,303.45 251.71,303.45 251.71,300.1 247.45,303.45 244.67,301.41 246.89,297.33 245.59,294.56 242.07,296.78 239.66,296.04 
		243.93,291.59 235.77,290.5 236.7,286.22 233.26,286.4 233.26,290.5 229.47,286.4 231.51,283.56 229.47,279.73 233.26,277.88 
		238,276.76 241.52,278.99 247.26,277.88 247.26,281.98 254.47,280.84 252.64,283.56 	"
        />
        <polygon
          className="st0"
          id="leaf-13"
          points="308.65,228.86 309.64,232.58 309.64,236.3 312.02,236.3 313.96,239.58 310.24,249.86 312.17,251.2 
		313.96,251.94 316.79,249.15 318.72,245.54 321.7,244.94 324.68,245.83 324.68,242.86 326.77,244.31 326.77,239.28 324.23,238.24 
		329.89,237.94 333.47,236.6 333.47,234.67 330.34,232.13 335.11,229.6 333.17,227.52 334.51,222.9 331.53,224.69 329.15,221.71 
		327.21,223.79 323.64,220.52 317.58,223.05 317.58,224.39 312.62,224.69 310.98,226.92 	"
        />
        <polygon
          className="st0"
          id="leaf-14"
          points="296.92,199.13 298.38,204.35 300.74,205.99 306.12,205.99 305.28,209.4 308.65,207.55 309.66,212.09 
		311,216.3 314.03,216.3 314.87,218.99 317.73,217.82 319.59,217.65 321.88,212.77 324.13,214.45 325.84,212.77 328.34,212.6 
		324.97,209.91 323.12,205.99 327.5,205.99 328.34,202.33 330.19,204.18 332.38,202.5 331.2,199.81 325.84,197.28 321.88,197.28 
		323.63,194.98 319.92,194.98 316.22,192.4 313.86,194.98 311.34,193.07 308.65,196.78 304.27,196.1 299.22,195.94 299.56,199.3 	
		"
        />
        <polygon
          className="st0"
          id="leaf-15"
          points="264.41,263.98 255.54,260.18 255.54,263.98 258.07,266.09 261.73,267.02 263.14,269.61 261.31,272.99 
		257.37,272.99 255.54,274.68 253.85,278.2 254.69,280.45 251.18,281.15 248.08,281.15 248.08,276.93 244.49,278.48 244.49,275.95 
		240.34,276.51 242.73,273.13 237.1,273.69 238.65,269.75 234.14,270.67 236.25,266.38 235.69,263.28 239.49,262.57 238.08,258.91 
		241.6,258.21 242.59,260.46 247.94,258.21 248.36,259.62 250.61,256.8 249.28,254.27 251.89,254.83 254.13,256.8 255.68,254.27 
		259.48,255.4 262.15,255.82 264.41,255.4 265.39,260.46 	"
        />
        <polygon
          className="st0"
          id="leaf-16"
          points="208.34,360.36 208.34,357.85 203.82,359.11 201.15,355.63 195.2,356.66 191.91,355.63 188.42,356.45 
		190.68,351.94 188.42,351.11 190.06,345.57 184.93,357.85 178.15,361.59 178.15,364.47 181.43,365.78 173.83,367.34 175.89,370.42 
		173.83,374.91 180,376.63 180.41,380.08 179.18,382.95 182.26,385.17 185.13,383.57 189.03,386.24 191.09,380.49 193.55,383.77 
		197.66,382.54 202.08,378.84 201.15,374.91 205.88,374.91 207.52,377.61 209.37,373.3 213.48,372.07 216.56,371.86 217.38,368.44 
		214.3,368.44 214.71,363.64 209.37,364.47 	"
        />
        <polygon
          className="st0"
          id="leaf-17"
          points="249.86,366.48 252.75,370 251.89,372.34 256.39,374.3 260.04,377.29 257.82,377.94 258.86,381.72 
		257.23,384.19 253.01,386.24 250.92,389.01 248.19,389.01 248.97,391.35 245.71,391.35 243.24,393.43 241.42,392.78 241.42,389.95 
		238.81,389.95 236.34,392.52 232.96,389.95 231.52,386.24 228.53,386.79 227.88,382.89 231.65,382.37 231.65,378.33 233.74,378.33 
		234.26,372.99 237.12,374.91 239.6,374.91 242.46,371.82 240.38,370.65 237.25,370.78 237.77,367.27 242.72,364.79 245.45,368.44 
		247.28,365.9 248.45,366.48 	"
        />
        <polygon
          className="st0"
          id="leaf-18"
          points="335.21,332.65 334.45,339.28 334.11,342.29 329.28,347.4 326.14,347.04 325.7,350.92 329.5,350.09 
		328.09,353.7 335.37,351.86 336,354.61 337.75,358.29 342.09,353.57 344.44,356.36 349.84,355.87 354.15,359.35 356.62,356.96 
		359.88,357.33 362.41,358.57 364.63,354.26 368.11,352.3 363.09,348.51 365.9,345.92 367.95,343.1 366.57,340.03 366.58,337.2 
		363.9,335.92 361.55,333.13 358.17,333.79 354.14,330.6 351.18,334.47 347.47,332.56 345.28,335.24 343.56,331.07 340.89,330.77 
		340.37,335.39 338.01,331.48 	"
        />
        <polygon
          className="st0"
          id="leaf-19"
          points="159.66,367.29 157.78,373.37 156.52,379.11 156.52,385.17 148.96,386.24 144.55,389.95 140.14,389.95 
		136.58,388.28 139.1,385.17 131.54,382.19 129.02,385.17 126.29,382.19 122.72,386.24 116.64,386.24 120.21,382.61 113.28,379.11 
		121.67,377.15 126.08,372.95 121.67,371.28 116.22,369.39 120.42,365.9 126.71,365.78 126.92,360.78 135.11,359.73 135.11,362.46 
		140.77,360.99 142.66,364.79 154.63,363.93 153.16,368.13 	"
        />
        <polygon
          className="st0"
          id="leaf-20"
          points="370.05,189.04 361.55,192.92 359.15,199.04 364.6,197.72 362.29,200.68 366.26,200.68 363.77,204.37 
		361,210.29 359.15,211.21 357.86,216.75 361.37,217.68 363.95,214.91 364.69,220.45 363.95,222.85 367.46,225.07 371.16,225.07 
		373.93,220.82 376.33,225.07 380.03,219.15 382.8,221.56 386.68,217.86 390.56,218.79 391.3,211.58 394.51,209.36 392.78,204.56 
		393.33,199.04 395.18,193.81 392.22,193.81 392.59,189.04 388.9,190.33 383.54,187.56 376.52,189.96 	"
        />
        <polygon
          className="st0"
          id="leaf-21"
          points="153.24,341.73 150.49,348.41 151.87,354.7 157.56,354.7 161.1,352.15 164.64,354.7 164.64,349.2 
		168.57,347.27 173.83,349.2 178.79,346.45 177.41,339.38 177.8,333.94 184.09,334.66 183.11,330.73 188.41,329.46 188.41,325.03 
		187.43,319.57 193.52,316.58 184.09,314.73 182.13,310.49 176.62,316.78 169.75,317.96 170.53,320.61 162.67,320.61 161.89,324.22 
		155.8,322.08 151.47,328.75 153.83,330.34 152.26,334.86 146.17,335.25 146.95,340.75 154.03,338 	"
        />
        <polygon
          className="st0"
          id="leaf-22"
          points="84.45,315.64 81.89,320.1 85.51,321.38 90.19,321.38 89.12,324.22 84.02,330.74 92.95,326.06 
		92.95,330.74 96.57,329.46 89.23,334.14 77.22,338.39 78.7,341.58 78.7,347.27 83.6,342.64 83.17,347.27 90.4,343.28 90.19,349.02 
		92.53,356.04 96.57,352.85 98.48,354.7 102.1,348.17 106.56,349.45 109.33,342.64 108.26,339.67 115.28,337.33 115.28,332.6 
		111.66,330.74 115.28,322.66 111.24,322.87 111.88,314.73 108.05,319.25 102.73,317.34 102.73,313.51 96.25,315.85 	"
        />
        <polygon
          className="st0"
          id="leaf-23"
          points="140.01,301.43 133.7,305.58 133.7,308.82 126.23,310.49 124.9,314.73 121.58,316.2 121.58,324.22 
		125.24,322.84 122.41,327.65 125.4,327.65 128.89,332.6 133.04,325.99 135.19,328.65 140.01,325 140.01,320.52 144.99,321.35 
		144.99,317.01 149.3,319.85 150.63,314.73 154.61,317.01 156.44,308.82 159.66,307.74 161.42,302.26 155.28,304.75 155.61,300.6 
		149.63,295.12 145.49,297.45 146.17,301.1 142.66,303.75 	"
        />
        <polygon
          className="st0"
          id="leaf-24"
          points="150.15,268.06 146.99,266.95 144.77,269.92 141.06,266.21 136.24,264.36 135.13,268.06 130.13,264.36 
		124.94,269.92 120.49,272.7 124.75,275.66 119.19,281.22 124.2,281.22 119.93,287.71 124.94,285.86 130.13,287.71 124.2,292.9 
		128.27,292.9 126.79,296.42 135.5,299.01 136.8,294.61 137.91,303.09 140.32,304.2 142.45,302.91 144.4,297.98 150.15,293.27 
		153.85,291.6 152.74,294.61 156.45,295.49 162.38,289.19 161.49,284.28 165.53,278.07 162.56,277.33 164.05,269.92 158.86,269.73 
		156.26,266.21 	"
        />
        <polygon
          className="st0"
          id="leaf-25"
          points="53.74,257.87 49.27,259.62 48.63,256.66 44.17,258.14 41.23,256.12 38.8,257.87 35.1,259.62 
		37.14,262.31 32.17,262.31 30.51,267.02 26.55,265.41 23.87,267.02 22.21,271.19 25.66,271.87 25.27,273.75 28.97,272.81 
		29.48,274.96 27.31,276.04 23.48,274.96 23.48,278.46 26.17,280.23 24.63,283.98 26.68,285.72 29.61,284.51 30.76,285.72 
		34.46,282.09 36,284.65 40.21,283.44 42.38,281.55 43.66,282.36 46.08,280.23 50.04,279.27 51.7,276.17 53.74,270.92 59.23,267.43 
		59.23,265.14 54.89,262.72 	"
        />
        <polygon
          className="st0"
          id="leaf-26"
          points="217.19,211.56 210.9,215.27 211.64,217.12 204.97,223.05 206.82,224.88 202.56,230.08 209.88,228.42 
		206.82,234.34 209.88,236.93 215.53,233.79 217.19,236.93 221.45,233.23 225.16,235.76 229.79,236.93 232.19,232.3 239,227.86 
		241.08,221.93 241.08,218.23 245.15,218.05 240.71,212.65 239.78,208.97 238.12,203.79 237.38,201.01 226.38,202.47 218.04,204.55 
			"
        />
        <polygon
          className="st0"
          id="leaf-27"
          points="100.29,254.31 105.03,257.58 107.2,257.58 103.75,261.25 104.48,263.43 109.11,265.15 113.33,266.33 
		113.33,271.32 113.33,275.41 108.16,275.95 109.73,278.13 112.24,282.21 118.23,282.89 114.14,286.57 116.59,289.56 112.1,290.11 
		108.29,286.98 105.71,290.11 102.3,290.11 99.58,290.11 102.03,285.75 99.58,282.89 96.25,282.21 98.63,278.13 91.55,278.67 
		91.55,276.22 86.65,275.41 83.93,272.96 89.92,268.47 88.83,265.15 93.32,263.84 93.46,258.94 96.25,257.58 	"
        />
        <polygon
          className="st0"
          id="leaf-28"
          points="405.08,226.92 405.08,234.5 407.7,239.21 400.5,241.75 393.83,239.74 402.53,250.2 400.76,253.34 
		396.97,256.57 391.47,259.1 389.38,256.57 384.78,259.1 382.32,252.56 374.47,253.34 370.13,249.42 377.87,248.11 371.33,244.18 
		375.78,238.95 372.38,235.29 370.13,229.53 366.36,226.92 371.85,219.33 376.56,226.13 379.7,221.16 383.36,224.82 391.74,224.82 
		395.14,228.45 	"
        />
        <polygon
          className="st0"
          id="leaf-29"
          points="289.5,203.16 296.01,212.84 292.76,213.89 284.53,211.27 279.56,209.96 268.47,209.96 262.81,205.7 
		265.39,197.4 269.87,200.02 276.15,194.98 284.53,196.62 282.96,198.97 291.45,199.24 	"
        />
        <polygon
          className="st0"
          id="leaf-30"
          points="179.09,247.11 178.04,244.31 182.75,239.79 173.83,242.04 172.02,237.43 167.58,244.31 161.42,244.31 
		161.42,249.15 156.85,251.3 161.42,258.1 156.85,262.81 156.85,269.88 165.48,267.02 173.83,267.71 176.73,269.88 182.75,267.02 
		187.72,270.92 193.52,259.67 188.24,258.1 186.94,254.31 177.78,254.31 	"
        />
        <polygon
          className="st0"
          id="leaf-31"
          points="219.49,223.04 225.18,223.04 237.66,227.23 237.66,235.09 239.97,242.04 234.14,252.88 229.73,258.42 
		229.73,261.66 221.25,263.05 219.49,257.96 210.39,256.81 214.32,248.37 208.08,246.87 206,240.4 196.76,243.05 198.84,236.23 
		208.31,234.86 207.39,227.69 211.55,226.77 211.78,220.52 	"
        />
        <polygon
          className="st0"
          id="leaf-32"
          points="196.76,301.63 205.31,300.16 207.16,295.12 210.86,293.42 210.86,288.26 204.85,286.48 203.81,280.24 
		196.76,281.15 195.61,284.9 185.9,283.84 184.75,288.26 178.97,290.11 173.83,300.16 169.84,304.17 177.35,303.02 181.97,297.01 
		185.67,303.02 190.35,300.16 193.52,303.02 	"
        />
        <polygon
          className="st0"
          id="leaf-33"
          points="382.54,345.74 388.73,354.58 385.63,356.3 390.34,366.76 379.78,366.33 374.62,361.12 368.13,367.32 
		371.17,378.17 382.88,376.45 385.63,382.81 396.14,374.91 409.04,382.47 411.45,387.78 419.03,385.22 414.77,375.27 425.23,378.17 
		432.11,373.52 417.86,366.76 423.5,356.65 408.61,364.24 407.32,348.81 397.54,353.39 	"
        />
        <polygon
          className="st0"
          id="leaf-34"
          points="410.14,331.54 411.87,324.91 417.34,326.06 417.34,316.85 421.09,319.93 425.12,321.28 429.19,330.7 
		441.53,329.52 441.53,337.29 437.5,341.9 440.38,347.37 434.91,354 435.77,357.45 427.84,348.58 423.68,357.45 419.07,347.23 
		410.14,352.56 402.08,347.66 407.84,345.2 403.81,340.46 402.08,332.69 413.22,337.15 	"
        />
        <polygon
          className="st0"
          id="leaf-35"
          points="474.87,337.68 461.98,338.87 467.18,343.29 468.05,353.02 477.29,348.4 481.62,355.91 492.6,360.24 
		494.78,351.13 504.15,358.7 508.78,351.34 516,355.91 516,350.13 505.31,344.93 503.58,337.68 488.99,339.16 494.33,335.4 
		498.38,332.23 494.62,327.42 491.73,332.23 483.66,334.6 482.49,326.07 	"
        />
        <polygon
          className="st0"
          id="leaf-36"
          points="427.26,351.19 424.32,358.37 434.44,360.24 436.07,373.34 446.51,368.16 452.32,375.8 459.57,369.8 
		462.37,373.34 464.13,364.93 473.6,371.37 481.11,367.88 484.05,359.35 474.91,359.68 473.93,352.84 470.01,344.69 463.49,354.56 
		454.18,350.54 449.27,360.24 426.93,348.04 	"
        />
        <polygon
          className="st0"
          id="leaf-37"
          points="265.59,185.6 267.55,192.14 271.14,197.67 261.67,200.49 256.45,197.67 266.37,207.85 259.3,216.3 
		268.32,217.14 271.14,227.04 276.39,219.7 283.54,222.47 288.76,228.35 290.59,217.14 303.45,215.27 299.21,209.98 303.45,203.66 
		293.37,197.38 287.25,200.44 283.87,188.79 278.32,194.58 273.7,185.6 	"
        />
        <polygon
          className="st0"
          id="leaf-38"
          points="463.86,314.54 463.86,322.67 455.59,324.89 455.59,327.99 447.4,327.99 443.05,332.72 433.53,327.54 
		433.53,321.57 425.81,323.63 421.13,317.8 427.77,315.59 429.1,306.73 435.08,306.73 441.28,300.54 441.5,295.22 448.58,296.41 
		455.59,293.23 455.59,296.41 461.86,296.41 461.64,300.98 458.98,305.85 463.86,307.18 460.09,312.98 	"
        />
        <polygon
          className="st0"
          id="leaf-39"
          points="513.89,311.62 512.6,328.75 516.13,321.21 517.42,325.74 523.51,320.61 528.65,332.58 531.72,324.22 
		531.72,336.33 536.35,336.33 540.52,328.75 546.3,332.58 542.44,321.21 550.47,321.21 546.94,313.87 550.47,303.02 542.77,306.37 
		539.88,295.58 533.46,299.83 523.83,293.03 524.8,299.83 516.77,296.86 510.04,301.46 513.89,303.02 511.23,304.7 504.71,308.82 
		513.57,308.82 	"
        />
        <polygon
          className="st0"
          id="leaf-40"
          points="495.92,281.15 502.01,277.93 502.01,267.71 506.51,267.71 513.89,261.88 516.77,267.71 525.12,261.88 
		525.12,271.52 531.72,271.52 534.74,273.76 538.27,284.9 534.74,288.2 531.72,283.06 525.44,286.48 523.19,277.93 520.95,281.72 
		520.3,288.2 514.85,286.48 507.79,293.42 510.36,287.87 501.37,290.12 500.73,284.9 	"
        />
        <polygon
          className="st0"
          id="leaf-41"
          points="337.51,275.99 337.51,284.9 330.77,286.48 327.27,293.03 332.39,297.61 336.95,303.02 349.02,293.42 
		343.4,305.19 350.7,305.19 359.4,301.46 360.53,295.36 366.98,297.33 361.09,286.48 369.51,284.9 369.51,275.99 362.21,280.48 
		363.05,272.34 357.72,267.02 356.32,277.65 350.98,277.65 351.55,267.02 345.09,279.08 343.12,272.63 	"
        />
        <polygon
          className="st0"
          id="leaf-42"
          points="430.14,264.6 436.03,267.02 443.25,261.88 441.09,270.38 448.39,264.6 448.39,270.38 456.53,263.36 
		464.61,268.41 462.98,256.91 473.09,255.22 464.61,250.73 471.57,242.87 464.61,242.87 459.89,236.23 448.67,236.23 446.98,242.31 
		434.91,241.47 436.88,247.64 433.79,251.94 422.56,251.94 422.56,257.75 418.07,259.43 418.07,267.02 	"
        />
        <polygon
          className="st0"
          id="leaf-43"
          points="378.09,273.34 386.81,278.18 385.11,280.97 392.97,280.97 386.81,288.75 391.01,290.92 392.97,296.05 
		398.86,299.41 395.3,306.68 390.44,299.41 386.81,303.34 386.81,308.67 376.41,301.94 371.8,306.68 363.22,303.06 367.71,298.29 
		359.01,296.05 359.01,293.64 354.82,289.59 365.46,291.01 361.81,282.34 369.39,285.1 367.71,276.68 373.88,280.97 	"
        />
        <polygon
          className="st0"
          id="leaf-44"
          points="429.02,275.43 420.32,286.1 414.42,286.1 414.42,290.87 408.61,295.36 410.77,299.83 415.26,298.17 
		415.26,303.02 420.03,299.83 422.56,305.19 427.61,301.46 433.51,308.27 434.63,301.46 438.21,303.02 443.25,298.45 447.54,293.42 
		441.09,288.62 447.54,283.29 441.93,283.01 439.66,277.65 428.08,284.9 	"
        />
        <polygon
          className="st0"
          id="leaf-45"
          points="62,220.52 53.02,222.1 48.52,220.52 46.28,224.57 40.72,226.82 39.54,237.43 43.13,238.5 
		40.72,247.26 46.28,245.01 47.4,247.26 55.49,247.26 57.29,252.65 64.7,241.78 64.7,245.91 71.21,240.52 62.68,232.43 
		75.26,231.76 65.37,226.59 71.21,223.04 	"
        />
        <polygon
          className="st0"
          id="leaf-46"
          points="68.99,197.44 68.99,204.57 59.27,208.45 59.17,214.23 65.98,214.23 60.68,225.51 68.99,221.19 
		70.11,226.81 75.28,220.75 76.85,225.51 89.43,222.98 87.41,218.05 95.5,218.72 91.78,212.13 93.7,201.43 84.27,207.49 
		89.66,199.4 89.88,193.11 83.13,193.11 84.94,187.05 79.55,184.35 76.85,189.07 73.71,185.74 71.69,194.24 	"
        />
        <polygon
          className="st0"
          id="leaf-47"
          points="146.17,190.65 138.84,197.39 135.47,203.16 140.82,205.25 135.47,207.05 133.67,210.64 133.67,214.01 
		129.4,216.71 132.32,218.95 137.78,220.52 141.5,215.36 144.45,217.38 152.32,211.09 158.6,215.36 159.73,209.07 164,210.42 
		167.82,209.29 166.47,201.43 168.71,198.73 163.55,193.57 161.42,196.26 159.28,190.65 154.11,196.71 153.21,190.65 150.29,193.12 
			"
        />
        <polygon
          className="st0"
          id="leaf-48"
          points="53.01,183.28 44.83,188.59 40.72,188.59 40.72,181.88 40.72,175.11 36.66,177.1 34.01,168.04 
		30.03,175.11 25.17,172.68 25.17,178.31 20.31,179.75 13.91,186.16 16.34,190.65 22.21,193 20.53,201.18 26.5,194.98 31.36,196.76 
		30.47,201.18 24.29,202.06 27.38,204.49 36,200.07 39.54,205.58 40.72,198.97 46.82,205.99 47.7,199.41 51.46,198.53 49.47,203.16 
		56.1,203.16 56.1,196.1 60.74,194.98 55.22,192.34 58.31,187.01 53.95,187.01 	"
        />
        <polygon
          className="st0"
          id="leaf-49"
          points="62.88,165.88 54.92,177.58 50.71,177.58 52.11,170.56 45.56,169.39 45.56,164.24 39.54,165.65 
		42.29,157.46 33.63,157.46 34.8,152.07 40.72,155.35 40.72,147.1 44.63,149.5 46.5,140.61 51.18,144.35 44.16,131.48 48.25,129.38 
		49.77,122.36 52.82,128.44 57.4,130.31 56.09,136.63 62.88,142.71 61.71,147.1 70.6,157.46 70.6,165.18 64.75,159.09 64.98,170.09 
			"
        />
        <polygon
          className="st0"
          id="leaf-50"
          points="89.55,129.85 83.93,128.21 83.93,122.36 77.12,121.42 77.12,116.98 72.47,113.93 70.6,108.32 
		67.32,113.47 61.94,112.53 57.4,117.44 63.81,124.93 60.74,129.85 69.19,129.85 64.51,135.46 64.98,141.55 69.19,145.05 
		72.24,151.37 77.12,148.33 77.12,138.97 83.93,141.78 83.93,136.16 91.89,138.74 95.29,131.95 89.55,134.29 	"
        />
        <polygon
          className="st0"
          id="leaf-51"
          points="106.63,142.48 101.08,145.76 95.29,142.48 93.06,148.8 85.81,149.97 85.81,156.99 78.55,159.33 
		76.34,164.24 77.12,166.82 79.72,173.6 75.26,175.01 81.6,177.58 90.95,169.86 91.89,174.54 97.04,177.58 101.08,173.6 
		106.63,177.58 112.25,172.9 115.99,175.01 123.01,171.26 119.74,168.04 123.01,168.04 120.2,160.03 123.01,157.46 116.46,151.37 
		116.93,147.1 110.84,149.97 113.45,143.42 	"
        />
        <polygon
          className="st0"
          id="leaf-52"
          points="374.69,227.22 369.51,233.54 368.13,239.33 362.05,233.28 356.78,239.33 356.78,230.91 347.04,234.59 
		341.25,236.23 344.14,230.12 339.93,227.22 342.56,223.04 336.77,220.52 342.83,216.42 348.39,212.47 360.47,215.63 369.51,217.48 
		377.06,218.99 381.27,228.54 	"
        />
        <polygon
          className="st0"
          id="leaf-53"
          points="415.76,243.02 413.66,247.49 408.61,244.31 405.76,247.49 401.81,245.26 400.23,239.33 394.17,241.78 
		387.85,241.78 385.11,237.23 379.69,239.33 386.54,231.7 387.33,225.9 386.54,223.04 394.44,225.9 401.81,229.85 410.5,229.06 
		419.45,232.22 414.18,235.27 421.82,239.33 414.45,239.33 	"
        />
        <polygon
          className="st0"
          id="leaf-54"
          points="464.89,224.21 466.47,230.69 471.21,230.69 471.21,235.96 477,234.64 477,243.07 467.52,243.07 
		467,245.96 460.68,247.54 467.26,250.18 467.26,253.49 475.16,249.39 477,252.28 487.53,249.12 488.85,244.12 496.75,247.54 
		498.07,241.26 503.86,241.26 499.48,233.85 490.43,233.06 490.96,226.48 480.95,227.8 480.95,223.92 474.37,218.84 	"
        />
        <polygon
          className="st0"
          id="leaf-55"
          points="466.94,195.72 463.25,203.66 468.78,206.52 461.41,207.83 465.1,211.52 472.73,216.84 474.31,211.52 
		483.07,212.84 480.1,206.28 487.74,206.28 487.74,199.61 493.8,199.61 495.11,193.09 487.74,191.77 480.89,193.09 474.31,191.77 
		463.78,186.6 457.2,188.61 450.94,192.04 440.34,195.19 448.61,198.56 446.66,201.72 450.94,199.61 454.3,201.72 459.83,197.3 
		461.41,199.61 	"
        />
        <polygon
          className="st0"
          id="leaf-56"
          points="103.81,128.96 98.33,126.7 101.07,122.69 93.78,118.92 93.78,110.9 98.8,110.9 98.33,100.36 
		102.18,100.36 106.32,95.84 110.34,97.1 118.62,85.05 118.62,92.08 127.15,95.09 120.12,99.86 128.65,107.01 124.64,109.14 
		128.65,116.92 122.38,115.41 126.05,127.96 118.12,134.98 117.86,128.96 108.33,134.98 110.09,126.95 	"
        />
        <polygon
          className="st0"
          id="leaf-57"
          points="195.61,65.49 189.59,66.49 191.09,58.71 184.57,62.6 175.54,57.21 175.54,66.49 168.71,69 
		172.03,71.76 165,76.78 170.52,84.8 162.74,91.58 167.26,95.59 162.74,97.6 164.5,101.87 179.18,91.83 180.3,105.63 188.08,100.86 
		195.61,105.63 202.13,103.62 204.89,108.32 207.65,100.61 203.39,93.96 206.65,86.81 202.38,82.76 205.14,80.29 207.65,79.29 
		207.65,71.51 198.12,74.77 	"
        />
        <polygon
          className="st0"
          id="leaf-58"
          points="169.35,45 164.35,40.95 159.36,45 150.32,38.33 151.84,43.09 143.18,45 137.23,45 133.66,52.61 
		133.66,57.01 145.46,54.51 143.89,60.7 140.56,62.84 148.06,69.98 152.93,63.08 154.84,68.8 160.78,63.08 167.68,64.5 
		166.97,57.01 171.5,55.94 178.15,54.16 168.64,51.42 174.58,48.8 165.31,47.85 	"
        />
        <polygon
          className="st0"
          id="leaf-59"
          points="182.36,32.58 177.6,35.91 168.56,32.58 160.47,34.25 162.74,37.58 158.33,50.9 164.27,57.21 
		167.37,49.47 172.36,50.43 172.36,40.43 177.12,51.61 183.31,54.35 186.4,48.05 193.06,54.35 203.06,53.04 199.72,44.89 
		203.06,40.43 195.68,38.53 197.82,30.44 189.97,34.96 191.63,24.02 	"
        />
        <polygon
          className="st0"
          id="leaf-60"
          points="450.64,185.18 449.03,190.19 445.58,190.91 444.55,186.79 439.89,189.12 439.89,184.28 433.26,182.85 
		434.99,179.62 440.25,179.62 440.97,175.32 434.99,173.89 433.26,167.26 439.36,167.26 442.94,169.95 448.13,167.26 452.43,169.05 
		460.67,163.14 457.09,169.59 467.48,162.07 467.48,166.55 473.09,168.16 473.09,172.46 468.55,174.25 473.09,182.85 469.81,182.67 
		468.91,187.86 470.7,188.58 468.91,190.91 462.37,188.76 459.6,194.98 456.91,189.3 453.18,190.37 	"
        />
        <polygon
          className="st0"
          id="leaf-61"
          points="325.5,129.16 318.96,134.73 313.22,129.16 299.92,131.95 310.7,140.89 320.39,140.89 329.89,138.55 
		335.22,145.29 335.22,153.5 347.83,146.17 352.14,154.84 355.46,147.05 361.25,148.81 357.8,138.84 364.25,137.96 371.29,134.44 
		368.07,126.67 361.25,128.28 357.51,122.71 352.14,126.67 343.14,118.9 346.95,113.32 335.61,110.68 334.05,124.47 325.5,116.07 
		328.23,126.67 	"
        />
        <polygon
          className="st0"
          id="leaf-62"
          points="332.38,180.37 332.38,190.65 337.7,194.98 339.75,204.43 345.62,200.6 348.39,203.16 352.66,199.14 
		360.28,205.99 368.13,203.16 373.78,204.43 368.13,197.67 369.51,190.65 362.04,192.4 360.58,187.11 353.08,187.7 353.08,178.52 
		348.39,183.28 342.49,178.52 342.49,185.64 	"
        />
        <polygon
          className="st0"
          id="leaf-63"
          points="425.33,84.61 419.03,91.34 412.96,91.34 416.21,98.5 410.57,102.94 419.03,104.14 417.95,110.22 
		410.57,111.74 405.14,118.03 406.23,124.11 416.86,117.82 415.24,131.27 419.9,129.32 423.16,133.44 429.45,127.15 434.23,132.14 
		443.78,128.67 437.7,126.28 451.16,123.46 453.18,125.85 461.36,123.68 459.84,118.9 463.31,116.95 457.45,113.69 453.18,110 
		457.45,104.58 453.18,100.89 447.47,104.36 447.9,95.03 439.65,99.59 439.87,90.91 431.62,90.25 432.27,81.36 	"
        />
        <polygon
          className="st0"
          id="leaf-64"
          points="500.21,121.07 497.38,127.15 500.21,132.36 500.21,141.26 492.91,141.26 490.87,147.1 496.52,147.1 
		498.25,153.63 503.03,147.1 506.72,150.37 515.61,143.65 514.51,151.24 521.26,147.1 527.33,148.64 527.33,138.87 532.98,137.35 
		532.98,131.49 524.73,131.27 524.73,124.11 517.57,128.45 515.83,121.07 510.84,125.02 507.58,121.07 	"
        />
        <polygon
          className="st0"
          id="leaf-65"
          points="280.3,223.37 283.22,216.9 284.82,217.92 290.22,210.77 292.41,212.67 299.12,212.23 297.18,215.15 
		307.29,221.72 305.83,227.11 302.33,223.37 302.91,233.1 298.97,228.57 292.85,235.14 290.07,230.62 282.34,236.74 284.38,229.89 
		275.01,229.3 280.74,226.38 275.01,221.28 	"
        />
        <polygon
          className="st0"
          id="leaf-66"
          points="482.49,147.54 488.33,152.06 492.27,152.06 496.79,156.15 494.31,156.15 496.21,158.92 490.87,161.26 
		495.48,166.95 490.87,170.16 492.27,173.8 485.12,170.89 480.3,173.8 477.24,168.41 472.13,165.93 474.76,160.67 469.36,160.67 
		468.19,156.44 464.98,159.21 463.31,162.07 462.37,154.4 466.29,151.04 471.11,151.19 475.2,145.35 476.36,147.25 479.58,141.56 	
		"
        />
        <polygon
          className="st0"
          id="leaf-67"
          points="391.55,155.68 393.57,162.07 397.06,164.5 400.75,167.63 405.14,168.73 405.14,176.45 403.31,182.33 
		397.25,178.52 398.35,185.64 393.39,189.68 392.83,183.07 387.5,189.68 385.48,183.98 384.56,181.78 373.78,184.54 373.78,181.78 
		379.69,177.37 371.14,173.8 372.43,165.93 364.71,163.76 366.37,159.35 373.78,159.54 372.25,155.68 379.69,157.68 381.27,153.63 
		384.93,157.68 	"
        />
        <polygon
          className="st0"
          id="leaf-68"
          points="389.89,123.14 383.28,128.29 384.93,129.39 376.84,133.44 379.23,137.35 378.17,145.93 376.84,151.27 
		384.93,151.27 387.14,153.63 393.39,151.27 399.45,154.94 402.21,151.82 411.4,151.08 405.14,147.1 410.48,143.18 411.4,139.04 
		410.11,133.44 400.74,138.21 402.58,132.33 399.82,125.9 396.88,130.31 396.51,121.07 392.28,128.29 	"
        />
        <polygon
          className="st0"
          id="leaf-69"
          points="444.72,132.47 448.69,137.48 451.32,141.78 456.01,137.12 460.86,141.78 466.58,138.91 471.03,150.13 
		468.49,155.85 464.44,152.51 466.11,157.76 459.9,157.76 457.87,164.2 449.65,162.77 450.12,167.06 442.25,167.06 439.63,160.38 
		435.33,167.06 432.71,157.52 439.39,151.08 430.56,149.77 439.87,145.59 432.71,140.58 439.63,141.78 439.63,137.72 444.72,138.2 	
		"
        />
        <polygon
          className="st0"
          id="leaf-70"
          points="374.28,43.21 367.53,34.34 361.84,40.61 355.63,37.3 350.31,43.21 339.66,43.21 339.66,35.52 
		326.36,49.42 328.43,57.21 321.73,62.73 331.84,57.21 334.05,67.76 341.14,60.07 345.28,71.31 354.06,69.83 356.52,75.45 
		366.2,77.52 366.2,67.17 377.22,67.76 373.08,61.25 380.48,59.48 380.48,51.79 371.6,52.68 	"
        />
        <polygon
          className="st0"
          id="leaf-71"
          points="231.72,114.78 225.22,107.4 225.22,114.78 214.57,114.78 220.19,118.93 205.4,121.07 207.65,139.92 
		218.37,128.98 218.56,135.19 228.47,134.89 227.58,127.5 236.39,131.93 242.66,123.66 250.06,131.93 257.05,123.66 265.14,118.93 
		260.11,114.78 268.1,106.2 262.81,102.94 262.81,93.96 257.05,102.94 245.62,96.44 239.97,99.99 244.44,111.23 234.97,105.02 	"
        />
        <polygon
          className="st0"
          id="leaf-72"
          points="379.39,393.43 379.39,382.31 383.52,387.78 395.12,389.95 395.12,400.76 402.85,399.5 410.33,403.85 
		421.16,395.41 418.07,407.2 421.16,417.91 412.18,413.82 400.27,415.87 393.31,408.59 386.87,415.87 381.97,408.59 375.01,412.36 
		375.01,405.54 368.13,408.59 368.13,403.08 378.36,399.5 373.72,393.43 	"
        />
        <polygon
          className="st0"
          id="leaf-73"
          points="405.65,57.84 397.39,63.59 405.65,63.59 394.52,73.65 403.41,77.96 399.02,87.43 410.44,91.27 
		423.97,89.09 420.08,79.75 430.08,82.99 431.53,87.43 440.5,80.11 446.96,83.35 446.96,72.93 454.15,74.56 457.02,65.84 
		451.44,65.84 455.58,57.84 446.96,60 448.4,47.06 440.5,47.06 438.34,54.25 428.64,44.25 425.77,55.33 420.08,44.25 414.27,56.05 	
		"
        />
        <polygon
          className="st0"
          id="leaf-74"
          points="298.27,142.55 285.34,143.21 290.37,147.68 270.79,145.12 276.55,155.51 273.84,160.14 263.6,158.83 
		260.55,162.88 267.38,171.13 258.22,176.86 276.55,176.86 281.83,182.78 287.49,176.86 294.68,186.4 298.27,179 305.19,181.37 
		314.08,179 328.38,182.78 322.38,173.11 326.65,171.13 322.38,164.48 335.61,160.14 324.13,158.83 325.57,149.4 317.33,156.23 
		315.51,147.68 309.75,150.84 314.44,138.64 307.61,138.64 307.97,145.12 	"
        />
        <polygon
          className="st0"
          id="leaf-75"
          points="357.96,164.25 354.49,159.26 360.34,156.01 355.35,152.53 350.36,153.63 347.76,148.63 344.94,151.88 
		332.38,143.64 332.38,148.19 323.24,141.56 318.68,147.98 329.53,156.22 320.64,163.17 321.88,171.66 328.23,164.9 329.53,173.8 
		335.11,175.1 339.51,168.59 347.54,178.52 351.01,173.8 359.02,178.52 362.95,185.95 368.13,183.28 368.13,173.8 366.85,167.29 
		362.3,169.68 	"
        />
        <polygon
          className="st0"
          id="leaf-76"
          points="187.91,185.95 199.02,199.58 205.4,193.91 205.4,197.4 215.72,197.18 211.87,192.26 215.72,184.77 
		207.65,181.88 209.69,172.14 203.37,172.14 195.97,160.03 193.52,169.74 184.21,165.82 178.11,173.88 186.82,174.31 175.19,179.8 
		178.33,187.16 172.45,190.65 175.19,197.62 182.03,198.05 182.03,192.26 186.82,196.09 	"
        />
        <polygon
          className="st0"
          id="leaf-77"
          points="390.15,196.39 400.75,192.4 405.14,194.98 411.4,201.78 412.18,208.47 416.59,203.51 421.82,208.47 
		424.78,202.86 432.11,207.17 428.08,214.29 433.26,220.52 428.08,221.11 422.84,217.95 419.82,223.04 418.07,227.65 412.18,220.52 
		408.61,225.9 403.44,218.99 397.4,220.52 395.46,212.47 390.15,214.29 394.12,210.03 386.19,210.03 391.36,207.39 387.7,205.7 
		384.93,199.41 394.12,200.63 	"
        />
        <polygon
          className="st0"
          id="leaf-78"
          points="226.6,52.87 221.35,54.12 221.35,49.37 214.36,54.12 212.36,39.97 218.35,42.62 219.48,36.63 
		225.35,38.88 230.1,30.63 235.84,33.7 243.84,23.38 249.08,29.38 249.08,35.63 254.83,35.13 249.83,44.37 256.83,49.62 
		251.86,55.29 246.34,45.87 242.09,55.29 236.84,58.61 235.84,48.37 227.85,63.61 	"
        />
        <polygon
          className="st0"
          id="leaf-79"
          points="266.63,56.36 260.2,60.75 268.15,68.41 260.2,67.15 251.54,71.54 257.31,75.88 263.57,73.23 
		260.92,93.95 268.15,83.11 270.8,89.61 277.31,85.67 282.85,90.34 286.46,82.87 295.62,86.72 292.01,77.8 302.13,80.42 
		307.43,77.08 300.68,73.71 294.9,73.95 292.97,67.15 299.24,67.15 303.09,60.11 295.38,58.83 292.01,56.36 287.19,60.75 
		283.33,56.36 282.37,49.13 276.83,60.75 271.28,60.11 	"
        />
        <polygon
          className="st0"
          id="leaf-80"
          points="315.61,93.96 303.34,93.96 303.34,96.21 294.36,99.04 297.91,105.89 286.81,112.26 293.89,114.39 
		294.6,123.36 306.8,118.88 318.68,112.74 323.98,116.94 321.88,107.4 329.78,105.42 331.2,97.39 325.77,98.8 334.03,88.65 
		326.48,91.05 321.88,84.16 321.88,92.67 314.91,85.82 	"
        />
        <polygon
          className="st0"
          id="leaf-81"
          points="396.25,87.34 391.12,80.03 386.92,87.34 378.17,83.69 368.13,84.16 359.57,89.24 368.13,93.96 
		364.71,98.18 375.28,93.96 373.78,107.4 378.17,107.4 377.91,115.24 384.93,112.26 387.46,116.94 396.25,116.94 400.74,109.35 
		406.97,109.35 399.92,103.76 409.1,96.55 401.82,93.96 405.14,88.16 	"
        />
        <polygon
          className="st0"
          id="leaf-82"
          points="296.87,26.38 289.39,36.23 293.13,43.7 296.87,38.27 299.69,48.56 311.14,46.48 317.59,48.56 
		323.31,44.72 331.16,54.17 331.16,40.27 342.4,40.27 335.2,31.97 338.39,26.38 331.16,24.68 324.73,15.5 313.43,19.58 
		307.74,13.46 305.02,26.04 299.69,19.24 	"
        />
        <polygon
          className="st0"
          id="leaf-83"
          points="196.13,150.73 193.37,144.31 188.86,144.31 188.86,135.69 181.82,140.38 173.28,134.15 170.91,139.92 
		159.2,139.69 161.51,146.3 167.74,146.3 167.74,150.73 157.23,150.73 170.91,157.22 175.58,154.23 176.68,163.7 172.35,165.08 
		174.2,169.85 187.59,168.93 189.9,160.82 196.13,163.23 202.25,159.77 196.13,157.22 	"
        />
        <polygon
          className="st0"
          id="leaf-84"
          points="158.33,156.17 150.57,149.97 152.41,142.48 145.49,145.56 139.72,142.48 138.33,151.37 140.87,161.25 
		146.64,160.03 146.64,166.79 137.41,170.95 145.26,173.25 140.41,179.8 150.34,176.48 155.71,179.8 155.71,171.87 166.03,173.25 
		160.26,168.18 172.45,164.25 175.26,166.79 171.57,156.17 164.42,157.32 166.26,151.37 	"
        />
      </g>
      <g id="Leaves_2">
        <polygon
          className="st0"
          id="leaf-85"
          points="295.4,60.02 297.46,58.34 296.39,55.86 299.92,55.81 298.44,53.55 300.39,51.86 298.69,48.46 
		302.41,47.37 302.35,42.39 304.42,41.95 303.65,37.7 304.85,34.16 301.75,35.44 301.08,31.92 299.13,33.19 297.55,31.34 
		295.8,33.23 294.12,31.38 292.49,34 290.8,31.42 288.45,34.57 286.87,32.82 285.66,36.05 283.69,36.28 283.74,39.81 281.29,34.86 
		281.37,41.19 279.49,42.15 280.67,43.69 277.66,44.04 280.19,47.23 277.73,49.75 279.01,52.43 282.2,50.11 280.9,54.07 
		285.03,52.15 287.13,54 284.45,55.79 285.21,58.49 288.31,57.2 286.89,60.13 291.95,57.78 293.32,59.42 	"
        />
        <polygon
          className="st0"
          id="leaf-86"
          points="250.64,70.83 254.17,69.34 258.26,70.74 259.82,58.49 260.54,52.3 261.87,42.95 261.05,40.07 
		259.74,40.61 257.76,40.11 256.08,42.76 254.37,42.51 255.6,46.71 252.99,46.21 253.04,50.55 249.31,47.89 246.97,50.23 
		243.8,49.61 243.86,53.95 241.63,54.77 240.48,57.67 237.59,57.58 236.97,60.73 240.4,61.75 241.89,65.02 246.36,64.97 
		246.54,69.43 	"
        />
        <polygon
          className="st0"
          id="leaf-87"
          points="256.4,120.87 257.83,118.11 262.03,118.06 260.87,112.96 263.32,108.36 262.64,106.18 267.54,106.12 
		264.59,103.24 265.94,98.29 261.83,101.63 258.34,100.03 257.95,98.39 255.43,100.79 256.19,103.52 253.69,103.92 255.15,107.73 
		250.13,109.71 250.17,113.27 246.08,113.32 246.12,116.24 246.91,120.98 250.31,125.14 252.77,122.37 255.16,123.98 	"
        />
        <polygon
          className="st0"
          id="leaf-88"
          points="254.64,96.42 254.6,92.59 258.02,89.07 262.4,86.46 257.63,86.52 254.54,87.84 256.5,83.98 
		256.47,81.61 256.43,78.32 253.38,78.36 249.76,79.32 248.02,77.33 244.95,79.92 245.73,84.29 243,85.06 241.76,88.36 
		239.96,90.02 242.18,92.92 240.58,96.59 244.58,94.9 245.9,98.53 248.81,97.59 251.3,98.47 	"
        />
        <polygon
          className="st0"
          id="leaf-89"
          points="246.33,133.6 249.54,129.94 249.51,127.66 254.85,126.12 252.9,130.97 250.62,132.47 255.61,133.48 
		258.6,136.26 261.26,135.02 261.32,139.71 263.88,140.75 263.11,144.91 267.43,146.2 267.48,151.02 264.25,149.86 264.6,156.28 
		262.63,159.12 259.62,154.6 257.37,155.17 257.3,149.94 251.92,149.34 256.33,148.21 252.96,146.24 248.79,145.36 250.75,141.18 
		247.27,141.49 248.7,137.45 245.74,137.09 246.35,134.8 	"
        />
        <polygon
          className="st0"
          id="leaf-90"
          points="235.45,87.09 235.41,83.82 231.46,80.46 233.39,78.42 233.35,75.26 229.6,73.17 228.18,69.9 
		225.33,74.35 223.85,69.45 219.36,71.21 217.18,68.15 214.53,68.18 210.08,64.83 210.02,59.78 203.76,53.68 201.87,53.7 
		194.14,51.02 199.62,55.49 200.04,58.64 203.07,59.24 202.72,61.64 206.79,63.98 203.55,67.56 206.09,69.16 204.24,72.09 
		202.22,72.49 208,80.37 211.22,86.38 214.99,84.95 218.69,88.28 221.41,84.24 226.13,88.18 227.33,83.41 	"
        />
        <polygon
          className="st0"
          id="leaf-91"
          points="263.96,180.6 265.5,182.99 268.46,181.75 268.43,178.58 272.35,175.52 268.33,170.74 269.63,163.33 
		266.72,162.31 266.7,160.65 261.05,164.19 260.26,161.33 254.1,163.67 250.2,165.23 252.81,169.27 250.3,173.52 246.78,170.4 
		242.03,175.44 243.89,179.79 242.11,182.52 243.21,185.83 243.4,188.99 241.11,187.21 238.1,187.1 239.49,189.95 242.39,193.08 
		242.59,196.7 245.74,195.45 246.4,191.22 250.21,190.57 252.42,186.47 253.81,188.87 254.38,186.15 257.76,187.01 260.67,182.9 
		260.33,179.89 	"
        />
        <polygon
          className="st0"
          id="leaf-92"
          points="289.48,114.76 280.88,110.27 275.82,108.57 277.49,101.76 280.82,105.88 283.66,104.97 286.55,108.22 
		286.69,101.65 287.95,97.03 285.08,95.1 278.3,96.06 278.22,89.48 282.38,89.43 282.33,85.49 285.81,83.69 284.45,79.55 
		293.06,84.7 295.3,89.28 300.75,87.28 303.88,91.8 309.79,91.73 305.6,96.16 310.96,97.74 305.66,100.76 311.02,102.89 
		311.07,106.61 308.29,112.12 305.77,109.74 301.27,111.19 299.36,116.39 296.49,114.46 	"
        />
        <polygon
          className="st0"
          id="leaf-93"
          points="290.78,196.11 294.49,193.26 292.61,192.41 295.49,188.45 290.66,186.33 291.65,178.22 290.54,176.08 
		286.42,178.29 290.82,172.14 288.95,171.29 288.7,168.68 289.09,164.53 286.24,163.47 285.55,160.42 281.4,160.47 281.48,166.8 
		279.06,165.52 280.46,172.49 273.4,184.58 270.84,188.97 268.52,186.16 265.56,185.76 265.6,189.25 260.37,189.32 261.52,194.54 
		260.47,197.61 263.73,195.39 267.13,196.4 267.18,200.14 270.94,197.48 274.91,201.14 278.02,196.27 287.56,200.11 285.76,196.17 	
		"
        />
        <polygon
          className="st0"
          id="leaf-94"
          points="289.17,125.7 283.59,122.01 281.61,122.92 278.71,129.82 285.4,143.26 289.49,151.85 298.08,147.54 
		298.05,144.44 305.15,145.46 305.07,139.25 308.17,138.99 310.29,130.77 308.89,125.46 313.28,121.65 311.24,120.78 304.44,123.53 
		303.74,120.88 299.13,124.54 298.2,120.94 294.72,125.64 292.27,122.35 	"
        />
        <polygon
          className="st0"
          id="leaf-95"
          points="326.01,120.6 322.64,124.26 318.62,119.05 325.97,116.98 325.17,110.9 323.16,108.03 326.73,106.01 
		330.52,106.86 330.47,102.91 336.05,103.92 337.09,100.49 341.94,100.43 343.24,103.83 346.99,102.35 351.68,103.55 352.62,107.13 
		358.74,107.6 354.28,110.54 360.95,112 355.78,115.71 360.13,118.36 358.38,122.51 355.35,124.9 357.21,129.72 353.21,126.17 
		349.66,129.81 348.53,124.98 343.89,128.26 343.65,123.05 338.64,125.1 336.87,126.73 335.03,123.16 330.89,122.67 328.76,125.22 	
		"
        />
        <polygon
          className="st0"
          id="leaf-96"
          points="316.49,158.47 313.72,157.69 311.64,153.27 315.13,153.23 311.58,148.27 315.07,148.22 311.53,144.56 
		316.99,138.56 321.62,138.51 321.66,141.86 325.88,138.46 328.69,140.46 326.51,144.56 327.84,147.33 331.34,145.06 333.75,145.77 
		329.55,150.27 337.71,151.27 336.84,155.56 340.27,155.33 340.22,151.24 344.06,155.29 342.06,158.15 344.15,161.96 340.37,163.86 
		335.66,165.03 332.11,162.84 326.38,164.03 326.33,159.93 319.14,161.15 320.93,158.41 	"
        />
        <polygon
          className="st0"
          id="leaf-97"
          points="255.65,216.83 254.61,213.12 254.57,209.4 252.19,209.43 250.21,206.17 253.81,195.85 251.86,194.54 
		250.06,193.81 247.26,196.64 245.37,200.28 242.4,200.91 239.41,200.05 239.45,203.03 237.35,201.6 237.41,206.63 239.95,207.64 
		234.3,208.01 230.74,209.39 230.76,211.33 233.92,213.82 229.19,216.41 231.15,218.47 229.86,223.1 232.82,221.28 235.24,224.23 
		237.15,222.12 240.76,225.35 246.79,222.75 246.77,221.41 251.73,221.05 253.34,218.8 	"
        />
        <polygon
          className="st0"
          id="leaf-98"
          points="267.74,246.41 266.22,241.21 263.84,239.6 258.46,239.67 259.26,236.25 255.91,238.14 254.85,233.61 
		253.45,229.42 250.42,229.45 249.55,226.77 246.7,227.98 244.85,228.17 242.62,233.08 240.35,231.43 238.66,233.13 236.16,233.33 
		239.56,235.98 241.46,239.88 237.08,239.93 236.29,243.6 234.41,241.77 232.25,243.48 233.46,246.15 238.85,248.61 242.81,248.56 
		241.09,250.89 244.79,250.85 248.52,253.38 250.85,250.77 253.4,252.64 256.05,248.91 260.43,249.53 265.48,249.64 265.1,246.27 	
		"
        />
        <polygon
          className="st0"
          id="leaf-99"
          points="306.06,190.19 314.97,193.88 314.93,190.08 312.37,188 308.7,187.12 307.26,184.55 309.05,181.14 
		312.99,181.1 314.8,179.39 316.44,175.85 315.57,173.6 319.08,172.86 322.18,172.82 322.23,177.04 325.8,175.45 325.83,177.98 
		329.97,177.37 327.62,180.78 333.25,180.15 331.75,184.11 336.24,183.14 334.18,187.45 334.78,190.54 330.99,191.29 332.44,194.94 
		328.93,195.68 327.92,193.44 322.6,195.76 322.16,194.36 319.94,197.2 321.31,199.72 318.68,199.19 316.42,197.24 314.9,199.8 
		311.09,198.72 308.41,198.33 306.16,198.78 305.12,193.72 	"
        />
        <polygon
          className="st0"
          id="leaf-100"
          points="354.35,84.12 354.38,86.62 358.88,85.31 361.59,88.75 367.54,87.66 370.84,88.64 374.32,87.78 
		372.11,92.32 374.38,93.12 372.81,98.68 377.79,86.34 384.52,82.52 384.49,79.64 381.19,78.37 388.77,76.71 386.68,73.66 
		388.68,69.15 382.49,67.5 382.04,64.06 383.24,61.17 380.13,58.99 377.27,60.63 373.34,58 371.35,63.78 368.85,60.52 364.76,61.81 
		360.38,65.56 361.36,69.48 356.63,69.54 354.96,66.85 353.16,71.19 349.07,72.47 345.99,72.72 345.21,76.14 348.29,76.11 
		347.94,80.91 353.27,80.02 	"
        />
        <polygon
          className="st0"
          id="leaf-101"
          points="312.75,78.5 309.83,75.02 310.65,72.66 306.13,70.77 302.45,67.82 304.66,67.14 303.57,63.38 
		305.17,60.88 309.37,58.78 311.42,55.99 314.15,55.96 313.35,53.62 316.6,53.58 319.05,51.47 320.88,52.1 320.91,54.94 
		323.52,54.9 325.96,52.3 329.38,54.83 330.85,58.52 333.84,57.93 334.54,61.83 330.77,62.39 330.82,66.43 328.74,66.45 
		328.28,71.8 325.39,69.92 322.92,69.95 320.09,73.07 322.19,74.22 325.31,74.05 324.83,77.57 319.92,80.1 317.14,76.49 
		315.35,79.05 314.17,78.48 	"
        />
        <polygon
          className="st0"
          id="leaf-102"
          points="229.95,123.91 229.87,117.24 229.83,114.21 233.98,108.54 237.14,108.5 237.09,104.6 233.42,105.89 
		234.37,102.13 227.38,104.87 226.41,102.23 224.21,98.8 220.5,104.02 217.82,101.55 212.52,102.71 207.8,99.8 205.65,102.48 
		202.37,102.52 199.71,101.62 198.05,106.16 194.84,108.54 200.3,111.68 197.84,114.6 196.16,117.65 197.91,120.53 198.26,123.33 
		201.08,124.27 203.76,126.74 207.03,125.67 211.43,128.32 213.88,124.11 217.8,125.54 219.63,122.6 221.87,126.52 224.55,126.49 
		224.49,121.84 227.32,125.42 	"
        />
        <polygon
          className="st0"
          id="leaf-103"
          points="402.94,76.6 404.75,70.49 405.94,64.73 405.87,58.68 413.41,57.51 417.77,53.75 422.18,53.7 
		425.77,55.33 423.29,58.46 430.88,61.35 433.36,58.34 436.13,61.29 439.64,57.19 445.73,57.12 442.21,60.79 449.17,64.2 
		440.8,66.27 436.45,70.52 440.88,72.14 446.36,73.97 442.2,77.5 435.91,77.7 435.76,82.7 427.58,83.85 427.55,81.12 421.9,82.66 
		419.97,78.88 408.02,79.89 409.43,75.68 	"
        />
        <polygon
          className="st0"
          id="leaf-104"
          points="409.67,102.07 412.34,95.35 410.89,89.08 405.19,89.15 401.69,91.75 398.12,89.24 398.18,94.74 
		394.28,96.72 388.99,94.85 384.07,97.66 385.53,104.72 385.21,110.17 378.91,109.52 379.94,113.44 374.65,114.77 374.71,119.2 
		375.75,124.65 369.7,127.71 379.15,129.45 381.17,133.66 386.59,127.31 393.46,126.04 392.64,123.4 400.5,123.31 401.24,119.69 
		407.36,121.75 411.6,115.03 409.22,113.47 410.74,108.93 416.82,108.47 415.97,102.97 408.93,105.81 	"
        />
        <polygon
          className="st0"
          id="leaf-105"
          points="478.78,127.32 481.28,122.83 477.65,121.6 472.97,121.65 474,118.8 479.02,112.22 470.15,117.01 
		470.09,112.33 466.49,113.65 473.77,108.88 485.73,104.48 484.2,101.31 484.14,95.63 479.3,100.31 479.67,95.68 472.49,99.75 
		472.63,94.01 470.21,87.02 466.21,90.26 464.27,88.43 460.74,95.01 456.25,93.78 453.57,100.62 454.67,103.59 447.68,106.01 
		447.74,110.74 451.38,112.56 447.86,120.68 451.9,120.42 451.36,128.57 455.13,124 460.47,125.85 460.52,129.67 466.98,127.25 	"
        />
        <polygon
          className="st0"
          id="leaf-106"
          points="423.39,142.21 429.65,137.98 429.61,134.74 437.06,132.98 438.34,128.73 441.64,127.21 441.54,119.2 
		437.9,120.62 440.67,115.77 437.68,115.81 434.13,110.91 430.07,117.56 427.88,114.93 423.11,118.64 423.16,123.12 418.17,122.35 
		418.23,126.69 413.88,123.9 412.61,129.04 408.6,126.8 406.87,135.02 403.66,136.14 401.97,141.64 408.09,139.08 407.8,143.23 
		413.85,148.63 417.97,146.26 417.24,142.62 420.71,139.92 	"
        />
        <polygon
          className="st0"
          id="leaf-107"
          points="478.71,159.02 481.87,160.09 484.06,157.1 487.81,160.76 492.65,162.56 493.72,158.84 498.77,162.48 
		503.89,156.86 508.3,154.02 504,151.11 509.49,145.48 504.49,145.54 508.67,139.01 503.69,140.92 498.48,139.13 504.35,133.87 
		500.27,133.92 501.71,130.38 492.97,127.89 491.73,132.31 490.51,123.84 488.09,122.76 485.97,124.08 484.09,129.04 478.4,133.81 
		474.71,135.53 475.79,132.51 472.07,131.67 466.22,138.04 467.17,142.94 463.2,149.2 466.18,149.9 464.78,157.34 469.97,157.46 
		472.61,160.95 	"
        />
        <polygon
          className="st0"
          id="leaf-108"
          points="510.19,184.71 514.63,182.91 515.31,185.86 519.76,184.33 522.72,186.31 525.12,184.53 528.8,182.74 
		526.73,180.07 531.71,180.01 533.31,175.28 537.28,176.85 539.95,175.2 541.55,171.01 538.1,170.38 538.46,168.49 534.77,169.48 
		534.23,167.33 536.39,166.23 540.23,167.26 540.19,163.76 537.49,162.02 538.97,158.26 536.91,156.53 533.99,157.78 532.82,156.58 
		529.17,160.26 527.6,157.72 523.41,158.98 521.26,160.89 519.97,160.1 517.57,162.26 513.63,163.27 512.01,166.39 510.03,171.66 
		504.58,175.23 504.61,177.51 508.98,179.88 	"
        />
        <polygon
          className="st0"
          id="leaf-109"
          points="439.78,202.48 446.03,198.7 445.27,196.86 451.86,190.86 449.99,189.05 454.18,183.79 446.89,185.55 
		449.87,179.58 446.79,177.03 441.18,180.24 439.47,177.12 435.26,180.87 431.53,178.39 426.88,177.27 424.53,181.93 417.77,186.46 
		415.77,192.41 415.82,196.11 411.74,196.34 416.25,201.69 417.22,205.35 418.95,210.52 419.73,213.28 430.71,211.69 439.02,209.51 
			"
        />
        <polygon
          className="st0"
          id="leaf-110"
          points="463.68,188.84 458.91,185.63 456.73,185.66 460.14,181.94 459.38,179.77 454.73,178.11 450.5,176.98 
		450.44,171.99 450.39,167.9 455.56,167.3 453.96,165.14 451.4,161.09 445.4,160.48 449.44,156.75 446.95,153.79 451.44,153.19 
		455.28,156.27 457.83,153.11 461.23,153.07 463.96,153.04 461.56,157.42 464.04,160.25 467.39,160.89 465.06,165 472.13,164.37 
		472.16,166.82 477.06,167.58 479.82,169.99 473.88,174.56 475.01,177.86 470.54,179.23 470.46,184.13 467.69,185.52 	"
        />
        <polygon
          className="st0"
          id="leaf-111"
          points="275.11,242.29 268.48,232.69 271.73,231.61 279.98,234.12 284.97,235.37 296.06,235.23 301.77,239.43 
		299.29,247.75 294.77,245.19 288.56,250.31 280.16,248.77 281.71,246.4 273.21,246.24 	"
        />
        <polygon
          className="st0"
          id="leaf-112"
          points="384.98,197 386.06,199.79 381.41,204.37 390.29,202.01 392.16,206.59 396.53,199.66 402.68,199.58 
		402.62,194.75 407.17,192.54 402.51,185.79 407.03,181.03 406.94,173.96 398.34,176.92 389.98,176.34 387.06,174.21 381.07,177.13 
		376.06,173.29 370.39,184.61 375.69,186.12 377.04,189.9 386.2,189.78 	"
        />
        <polygon
          className="st0"
          id="leaf-113"
          points="366.64,142.7 358.11,144.27 356.33,149.33 352.65,151.08 352.71,156.24 358.74,157.95 359.86,164.18 
		366.89,163.17 368,159.42 377.72,160.36 378.82,155.92 384.57,154.01 389.59,143.89 393.53,139.83 386.03,141.07 381.49,147.14 
		377.72,141.17 373.07,144.09 369.87,141.27 	"
        />
        <polygon
          className="st0"
          id="leaf-114"
          points="180.35,100.86 174.04,92.09 177.12,90.33 172.29,79.93 182.85,80.23 188.08,85.38 194.49,79.1 
		191.31,68.29 179.63,70.15 176.8,63.83 166.38,71.86 153.39,64.46 150.92,59.17 143.37,61.82 147.75,71.73 137.26,68.95 
		130.43,73.69 144.77,80.27 139.25,90.45 154.04,82.67 155.52,98.09 165.25,93.39 	"
        />
        <polygon
          className="st0"
          id="leaf-115"
          points="122.8,108.71 121.15,115.36 115.66,114.28 115.78,123.49 112,120.46 107.95,119.15 103.76,109.79 
		91.43,111.12 91.34,103.34 95.31,98.69 92.37,93.25 97.76,86.56 96.85,83.12 104.89,91.89 108.95,82.97 113.68,93.13 122.54,87.69 
		130.66,92.5 124.93,95.03 129.02,99.72 130.85,107.47 119.65,103.14 	"
        />
        <polygon
          className="st0"
          id="leaf-116"
          points="72.31,103.72 85.19,102.37 79.93,98.02 78.95,88.29 69.76,93.03 65.34,85.57 54.31,81.37 52.24,90.51 
		42.78,83.06 38.25,90.48 30.97,85.99 31.04,91.77 41.79,96.84 43.61,104.07 58.18,102.41 52.88,106.23 48.88,109.46 52.69,114.22 
		55.52,109.38 63.56,106.9 64.84,115.43 	"
        />
        <polygon
          className="st0"
          id="leaf-117"
          points="123.26,86.98 126.11,79.76 115.97,78.02 114.18,64.95 103.8,70.25 97.9,62.68 90.73,68.77 
		87.89,65.27 86.23,73.69 76.68,67.37 69.21,70.95 66.38,79.51 75.51,79.08 76.58,85.9 80.59,94 87,84.05 96.35,87.96 101.14,78.2 
		123.63,90.13 	"
        />
        <polygon
          className="st0"
          id="leaf-118"
          points="169.19,146.91 167.15,140.39 163.49,134.9 172.92,131.96 178.18,134.72 168.14,124.67 175.11,116.13 
		166.07,115.4 163.14,105.53 157.97,112.94 150.79,110.25 145.5,104.44 143.81,115.67 130.97,117.7 135.28,122.93 131.11,129.3 
		141.26,135.46 147.35,132.32 150.87,143.94 156.35,138.07 161.08,147.01 	"
        />
        <polygon
          className="st0"
          id="leaf-119"
          points="77.44,144.83 77.34,136.7 85.58,134.38 85.54,131.28 93.72,131.18 98.02,126.4 107.6,131.46 
		107.67,137.43 115.36,135.28 120.12,141.05 113.5,143.34 112.28,152.21 106.31,152.28 100.18,158.56 100.03,163.87 92.93,162.77 
		85.96,166.04 85.92,162.86 79.65,162.93 79.81,158.36 82.41,153.46 77.53,152.19 81.22,146.35 	"
        />
        <polygon
          className="st0"
          id="leaf-120"
          points="70.67,126.6 71.75,109.45 68.31,117.04 66.97,112.53 60.94,117.73 55.66,105.82 52.69,114.22 
		52.54,102.11 47.91,102.17 43.83,109.79 38.01,106.04 42,117.36 33.98,117.46 37.6,124.76 34.2,135.64 41.86,132.2 44.88,142.96 
		51.25,138.63 60.95,145.31 59.91,138.52 67.97,141.4 74.65,136.71 70.78,135.2 73.42,133.48 79.88,129.29 71.03,129.4 	"
        />
        <polygon
          className="st0"
          id="leaf-121"
          points="67.76,166.82 61.7,170.12 61.83,180.34 57.33,180.39 50.03,186.31 47.07,180.52 38.8,186.45 
		38.68,176.81 32.08,176.89 29.03,174.69 25.36,163.59 28.85,160.25 31.94,165.35 38.18,161.86 40.53,170.38 42.73,166.56 
		43.29,160.08 48.76,161.73 55.74,154.7 53.24,160.28 62.2,157.92 62.9,163.14 	"
        />
        <polygon
          className="st0"
          id="leaf-122"
          points="234.27,161.9 234.16,152.99 240.88,151.33 244.3,144.74 239.13,140.22 234.5,134.86 222.55,144.61 
		228.02,132.78 220.72,132.87 212.07,136.7 211.02,142.81 204.54,140.92 210.57,151.7 202.17,153.38 202.27,162.29 209.52,157.71 
		208.77,165.86 214.17,171.11 215.45,160.47 220.78,160.4 220.35,171.04 226.65,158.9 228.7,165.33 	"
        />
        <polygon
          className="st0"
          id="leaf-123"
          points="133.73,182.57 127.81,180.22 120.66,185.45 122.72,176.93 115.49,182.79 115.42,177.02 107.36,184.13 
		99.22,179.18 100.99,190.67 90.9,192.47 99.43,196.86 92.58,204.8 99.53,204.72 104.33,211.3 115.55,211.17 117.16,205.07 
		129.24,205.76 127.2,199.61 130.24,195.28 141.47,195.14 141.4,189.33 145.87,187.59 145.77,180 	"
        />
        <polygon
          className="st0"
          id="leaf-124"
          points="172.71,202.39 163.94,197.66 165.6,194.84 157.75,194.94 163.81,187.09 159.59,184.97 157.56,179.86 
		151.63,176.57 155.1,169.26 160.05,176.47 163.63,172.5 163.57,167.16 174.05,173.77 178.6,168.97 187.23,172.49 182.79,177.32 
		191.52,179.44 191.55,181.86 195.79,185.86 185.13,184.57 188.88,193.2 181.27,190.53 183.06,198.93 176.83,194.71 	"
        />
        <polygon
          className="st0"
          id="leaf-125"
          points="134.72,171.73 143.29,160.95 149.19,160.88 149.13,156.11 154.88,151.55 152.67,147.11 148.2,148.82 
		148.14,143.97 143.41,147.22 140.82,141.9 135.81,145.68 129.83,138.94 128.79,145.77 125.2,144.25 120.21,148.89 115.98,153.97 
		122.49,158.68 116.1,164.09 121.72,164.31 124.06,169.64 135.55,162.25 	"
        />
        <polygon
          className="st0"
          id="leaf-126"
          points="502.38,222.16 511.35,220.47 515.86,222 518.06,217.92 523.59,215.61 524.64,204.97 521.03,203.96 
		523.34,195.17 517.81,197.48 516.66,195.25 508.57,195.35 506.71,189.98 499.43,200.93 499.38,196.81 492.93,202.28 501.56,210.26 
		488.99,211.09 498.94,216.13 493.14,219.75 	"
        />
        <polygon
          className="st0"
          id="leaf-127"
          points="471.19,233.97 471.1,226.84 480.77,222.84 480.8,217.06 473.99,217.15 479.15,205.8 470.9,210.22 
		469.7,204.62 464.61,210.75 462.98,206 450.43,208.68 452.51,213.59 444.42,213.01 448.22,219.56 446.43,230.29 455.79,224.11 
		450.49,232.26 450.35,238.55 457.1,238.47 455.36,244.56 460.79,247.19 463.42,242.44 466.61,245.73 468.53,237.21 	"
        />
        <polygon
          className="st0"
          id="leaf-128"
          points="418.59,253.06 425.84,246.23 429.14,240.42 423.76,238.39 429.09,236.53 430.84,232.91 430.8,229.54 
		435.04,226.8 432.09,224.59 426.61,223.09 422.95,228.29 419.98,226.31 412.19,232.69 405.85,228.5 404.8,234.8 400.52,233.51 
		396.71,234.68 398.16,242.52 395.94,245.25 401.17,250.35 403.27,247.63 405.48,253.22 410.57,247.09 411.54,253.15 414.43,250.64 
			"
        />
        <polygon
          className="st0"
          id="leaf-129"
          points="511.83,259.29 519.94,253.88 524.05,253.83 524.13,260.54 524.22,267.31 528.25,265.27 531.01,274.29 
		534.9,267.18 539.79,269.55 539.72,263.92 544.56,262.42 550.89,255.94 548.41,251.48 542.51,249.19 544.08,241 538.19,247.27 
		533.31,245.55 534.14,241.12 540.32,240.16 537.19,237.77 528.63,242.29 525.03,236.83 523.92,243.45 517.74,236.51 516.93,243.1 
		513.19,244.02 515.12,239.37 508.5,239.45 508.58,246.51 503.96,247.69 509.51,250.26 506.48,255.62 510.84,255.57 	"
        />
        <polygon
          className="st0"
          id="leaf-130"
          points="416.17,170.83 418.33,167.05 425.61,166.07 425.55,160.99 430.38,158.72 430.31,152.54 426.52,149.87 
		426.44,143.69 430.19,142.79 430.14,138.85 425.28,138.9 423.98,133.18 420.18,137.42 414.42,136.39 414.52,143.83 411.17,141.51 
		412.82,150.03 410.15,147.93 410.25,156.13 405.76,157.26 409.24,163.84 405.88,166.98 411.94,168.23 	"
        />
        <polygon
          className="st0"
          id="leaf-131"
          points="502.17,276.81 509.98,265.01 514.2,264.96 512.88,272 519.44,273.09 519.51,278.24 525.51,276.76 
		522.86,284.98 531.52,284.87 530.42,290.27 524.46,287.07 524.56,295.32 520.62,292.96 518.86,301.88 514.13,298.19 521.31,310.97 
		517.24,313.13 515.81,320.17 512.69,314.12 508.08,312.31 509.31,305.97 502.45,299.97 503.57,295.58 494.55,285.33 494.46,277.6 
		500.38,283.62 500.02,272.62 	"
        />
        <polygon
          className="st0"
          id="leaf-132"
          points="475.94,313.16 481.58,314.73 481.65,320.58 488.47,321.44 488.52,325.88 493.21,328.87 495.15,334.46 
		498.37,329.27 503.76,330.14 508.24,325.17 501.74,317.76 504.75,312.81 496.29,312.92 500.91,307.24 500.36,301.17 496.11,297.71 
		492.99,291.43 488.14,294.53 488.26,303.89 481.42,301.16 481.48,306.78 473.49,304.3 470.18,311.13 475.88,308.72 	"
        />
        <polygon
          className="st0"
          id="leaf-133"
          points="458.71,300.74 464.22,297.4 470.05,300.6 472.2,294.26 479.44,293 479.35,285.98 486.58,283.55 
		488.73,278.61 487.92,276.05 485.23,269.29 489.68,267.83 483.31,265.34 474.06,273.17 473.05,268.51 467.87,265.53 463.88,269.55 
		458.28,265.64 452.72,270.39 448.95,268.33 441.98,272.16 445.29,275.34 442.02,275.38 444.92,283.36 442.14,285.96 448.77,291.97 
		448.35,296.25 454.4,293.3 451.88,299.89 	"
        />
        <polygon
          className="st0"
          id="leaf-134"
          points="189.64,219.27 194.74,212.89 196.05,207.08 202.2,213.06 207.39,206.94 207.5,215.37 217.19,211.56 
		222.97,209.86 220.14,216.01 224.39,218.85 221.81,223.06 227.63,225.51 221.63,229.68 216.11,233.7 204,230.69 194.93,228.95 
		187.37,227.53 183.04,218.04 	"
        />
        <polygon
          className="st0"
          id="leaf-135"
          points="148.37,203.98 150.42,199.48 155.51,202.6 158.32,199.38 162.3,201.57 163.95,207.47 169.98,204.95 
		176.3,204.87 179.09,209.4 184.49,207.22 177.74,214.94 177.02,220.74 177.84,223.59 169.91,220.83 162.49,216.97 153.81,217.87 
		144.82,214.82 150.05,211.71 142.36,207.74 149.73,207.65 	"
        />
        <polygon
          className="st0"
          id="leaf-136"
          points="67.47,242 65.81,235.53 61.07,235.59 61,230.32 55.23,231.71 55.12,223.28 64.6,223.17 65.09,220.27 
		71.39,218.61 64.78,216.06 64.74,212.75 56.89,216.94 55.01,214.07 44.52,217.36 43.26,222.37 35.32,219.05 34.08,225.35 
		28.29,225.42 32.76,232.77 41.82,233.45 41.37,240.04 51.36,238.6 51.41,242.48 58.05,247.47 	"
        />
        <polygon
          className="st0"
          id="leaf-137"
          points="102.9,232.44 106.49,224.46 100.92,221.67 108.28,220.27 104.55,216.62 96.85,211.39 95.33,216.74 
		86.57,215.53 89.61,222.04 81.97,222.14 82.05,228.81 76,228.89 74.76,235.42 82.15,236.65 88.98,235.25 95.58,236.48 
		106.17,241.52 112.73,239.43 118.94,235.94 129.5,232.65 121.19,229.38 123.1,226.2 118.85,228.36 115.46,226.29 109.99,230.78 
		108.38,228.49 	"
        />
        <polygon
          className="st0"
          id="leaf-138"
          points="464.93,330.24 470.44,332.43 467.74,336.48 475.08,340.15 475.18,348.18 470.16,348.24 470.76,358.77 
		466.91,358.82 462.82,363.39 458.79,362.18 450.66,374.32 450.57,367.3 442.01,364.39 448.97,359.54 440.36,352.5 444.34,350.31 
		440.24,342.59 446.53,344.02 442.7,331.52 450.55,324.4 450.88,330.41 460.34,324.28 458.68,332.33 	"
        />
        <polygon
          className="st0"
          id="leaf-139"
          points="358.09,371.59 364.1,370.52 362.69,378.31 369.17,374.34 378.26,379.63 378.15,370.35 384.94,367.75 
		381.6,365.03 388.56,359.93 382.94,351.97 390.64,345.1 386.07,341.14 390.57,339.08 388.76,334.84 374.2,345.05 372.91,331.27 
		365.19,336.13 357.6,331.45 351.11,333.54 348.29,328.88 345.62,336.62 349.97,343.21 346.79,350.4 351.11,354.4 348.38,356.91 
		345.88,357.94 345.98,365.72 355.47,362.34 	"
        />
        <polygon
          className="st0"
          id="leaf-140"
          points="419.62,398.51 424.67,402.5 429.61,398.39 438.74,404.94 437.15,400.2 445.79,398.19 451.74,398.12 
		455.22,390.46 455.16,386.06 443.4,388.71 444.89,382.5 448.19,380.32 440.61,373.27 435.82,380.23 433.84,374.53 427.97,380.33 
		421.05,378.98 421.85,386.47 417.34,387.6 410.71,389.46 420.26,392.08 414.34,394.77 423.63,395.61 	"
        />
        <polygon
          className="st0"
          id="leaf-141"
          points="384.33,411.55 389.04,408.17 398.13,411.39 406.19,409.62 403.88,406.32 408.13,392.94 402.11,386.71 
		399.11,394.48 394.1,393.59 394.22,403.58 389.33,392.46 383.11,389.8 380.09,396.14 373.36,389.92 363.38,391.35 366.81,399.45 
		363.53,403.96 370.93,405.77 368.89,413.88 376.69,409.27 375.15,420.23 	"
        />
        <polygon
          className="st0"
          id="leaf-142"
          points="114.2,262.24 115.75,257.2 119.19,256.45 120.27,260.55 124.9,258.17 124.96,263 131.61,264.36 
		129.92,267.6 124.66,267.67 123.99,271.97 129.99,273.33 131.8,279.94 125.71,280.01 122.09,277.37 116.93,280.12 112.61,278.38 
		104.44,284.39 107.94,277.9 97.65,285.55 97.59,281.07 91.97,279.53 91.91,275.23 96.42,273.38 91.79,264.84 95.07,264.98 
		95.9,259.78 94.1,259.08 95.86,256.73 102.43,258.8 105.13,252.55 107.88,258.2 111.6,257.08 	"
        />
        <polygon
          className="st0"
          id="leaf-143"
          points="233.23,324.56 239.7,318.9 245.51,324.41 258.78,321.46 247.88,312.64 238.2,312.76 228.73,315.22 
		223.32,308.54 223.22,300.33 210.69,307.82 206.28,299.2 203.06,307.03 197.24,305.34 200.81,315.27 194.37,316.23 187.38,319.83 
		190.7,327.56 197.49,325.87 201.3,331.4 206.62,327.37 215.72,335.03 211.97,340.65 223.35,343.15 224.74,329.35 233.39,337.65 
		230.54,327.08 	"
        />
        <polygon
          className="st0"
          id="leaf-144"
          points="232.52,265.61 232.39,255.33 227.01,251.06 224.85,241.63 219.03,245.53 216.23,243.01 212.01,247.09 
		204.3,240.33 196.49,243.25 190.83,242.05 196.55,248.74 195.26,255.78 202.7,253.93 204.24,259.21 211.73,258.53 211.84,267.7 
		216.47,262.89 222.43,267.57 222.34,260.45 	"
        />
        <polygon
          className="st0"
          id="leaf-145"
          points="140.74,362.49 146.95,355.68 153.03,355.61 149.68,348.49 155.27,343.98 146.79,342.88 147.81,336.79 
		155.17,335.18 160.51,328.82 159.36,322.76 148.8,329.18 150.26,315.71 145.62,317.72 142.31,313.63 136.1,320 131.26,315.07 
		121.76,318.66 127.86,320.97 114.44,323.96 112.39,321.59 104.24,323.86 105.81,328.62 102.37,330.62 108.27,333.8 112.58,337.44 
		108.38,342.91 112.69,346.55 118.36,343.01 118.04,352.35 126.23,347.69 126.12,356.37 134.38,356.92 133.83,365.83 	"
        />
        <polygon
          className="st0"
          id="leaf-146"
          points="89.92,313.84 92.67,307.73 89.78,302.55 89.67,293.65 96.96,293.57 98.93,287.7 93.29,287.77 
		91.48,281.26 86.78,287.85 83.05,284.62 74.24,291.45 75.25,283.85 68.55,288.07 62.46,286.61 62.57,296.37 56.95,297.96 
		57.02,303.82 65.27,303.94 65.36,311.1 72.47,306.67 74.29,314.03 79.24,310.02 82.54,313.93 	"
        />
        <polygon
          className="st0"
          id="leaf-147"
          points="69.89,269.43 67.05,275.94 65.43,274.94 60.12,282.15 57.91,280.28 51.2,280.8 53.1,277.86 
		42.92,271.42 44.31,266 47.86,269.7 47.16,259.98 51.15,264.46 57.2,257.82 60.03,262.31 67.68,256.09 65.72,262.97 75.1,263.44 
		69.41,266.42 75.2,271.46 	"
        />
        <polygon
          className="st0"
          id="leaf-148"
          points="77.11,287.2 71.22,282.75 67.28,282.79 62.71,278.76 65.19,278.73 63.26,275.98 68.56,273.59 
		63.89,267.95 68.45,264.69 67.01,261.06 74.2,263.89 78.98,260.91 82.11,266.27 87.24,268.69 84.68,273.97 90.08,273.91 
		91.3,278.12 94.47,275.31 96.11,272.44 97.15,280.1 93.26,283.5 88.44,283.41 84.43,289.3 83.24,287.41 80.09,293.14 	"
        />
        <polygon
          className="st0"
          id="leaf-149"
          points="173.65,291.02 171.55,284.65 168.03,282.26 164.3,279.18 159.9,278.13 159.8,270.41 161.56,264.51 
		167.67,268.24 166.49,261.14 171.4,257.04 172.03,263.65 177.28,256.96 179.37,262.64 180.32,264.83 191.07,261.94 191.1,264.7 
		185.24,269.18 193.83,272.64 192.64,280.54 200.39,282.6 198.79,287.03 191.37,286.94 192.95,290.78 185.48,288.87 183.95,292.94 
		180.25,288.93 	"
        />
        <polygon
          className="st0"
          id="leaf-150"
          points="175.7,323.53 182.26,318.3 180.59,317.22 188.63,313.07 186.19,309.19 187.14,300.6 188.41,295.25 
		180.32,295.35 178.09,293.01 171.87,295.45 165.76,291.85 163.04,295.01 153.86,295.85 160.16,299.76 154.87,303.75 154,307.89 
		155.36,313.47 164.67,308.59 162.91,314.49 165.74,320.89 168.63,316.45 169.11,325.68 173.25,318.41 	"
        />
        <polygon
          className="st0"
          id="leaf-151"
          points="86.4,355.71 82.37,350.75 79.69,346.49 75.05,351.19 70.15,346.6 64.46,349.53 59.87,338.38 
		62.34,332.62 66.44,335.91 64.71,330.68 70.91,330.61 72.86,324.14 81.1,325.47 80.57,321.19 88.45,321.09 91.15,327.74 
		95.36,321.01 98.1,330.52 91.5,337.04 100.35,338.24 91.09,342.53 98.31,347.45 91.38,346.34 91.43,350.4 86.33,349.98 	"
        />
        <polygon
          className="st0"
          id="leaf-152"
          points="181.64,403.39 188.5,412.18 194.11,405.84 200.36,409.08 205.61,403.1 216.26,402.97 216.35,410.66 
		229.49,396.6 227.32,388.84 233.95,383.23 223.91,388.88 221.57,378.36 214.57,386.13 210.29,374.95 201.53,376.53 199.01,370.94 
		189.31,368.99 189.43,379.34 178.4,378.88 182.62,385.34 175.25,387.2 175.34,394.89 184.2,393.9 	"
        />
        <polygon
          className="st0"
          id="leaf-153"
          points="333.96,329.96 340.56,337.26 340.47,329.88 351.11,329.75 345.45,325.68 360.2,323.35 357.72,304.53 
		347.15,315.61 346.87,309.4 336.97,309.81 337.95,317.2 329.09,312.87 322.91,321.22 315.42,313.03 308.52,321.39 300.5,326.22 
		305.57,330.31 297.69,338.98 303.02,342.18 303.13,351.16 308.78,342.11 320.29,348.47 325.9,344.85 321.29,333.67 330.83,339.76 	
		"
        />
        <polygon
          className="st0"
          id="leaf-154"
          points="209.86,214.7 210,225.82 205.81,220.4 194.18,218.38 194.05,207.57 186.33,208.92 178.8,204.66 
		168.07,213.23 171.02,201.4 167.8,190.74 176.83,194.71 188.71,192.51 195.76,199.71 202.11,192.35 207.1,199.57 214.01,195.72 
		214.1,202.53 220.94,199.4 221,204.92 210.82,208.62 215.53,214.63 	"
        />
        <polygon
          className="st0"
          id="leaf-155"
          points="157.2,383.97 165.39,378.12 157.13,378.22 168.14,368.03 159.2,363.82 163.47,354.3 152,350.6 
		138.5,352.94 142.5,362.23 132.46,359.12 130.96,354.69 122.08,362.12 115.58,358.97 115.7,369.38 108.5,367.84 105.73,376.59 
		111.31,376.52 107.27,384.57 115.86,382.31 114.58,395.26 122.48,395.17 124.55,387.96 134.37,397.83 137.11,386.73 142.93,397.73 
		148.6,385.87 	"
        />
        <polygon
          className="st0"
          id="leaf-156"
          points="207.13,282.03 210.67,286.98 204.85,290.31 209.88,293.72 214.86,292.56 217.52,297.53 220.3,294.24 
		232.96,302.33 232.91,297.78 242.13,304.3 246.6,297.83 235.66,289.72 244.47,282.66 243.12,274.19 236.85,281.02 235.44,272.13 
		229.85,270.91 225.52,277.47 217.37,267.64 213.96,272.4 205.9,267.78 201.88,260.4 196.73,263.13 196.84,272.61 198.2,279.11 
		202.73,276.66 	"
        />
        <polygon
          className="st0"
          id="leaf-157"
          points="376.9,258.26 365.63,244.77 359.32,250.51 359.27,247.03 348.96,247.37 352.87,252.25 349.11,259.79 
		357.21,262.57 355.29,272.34 361.61,272.27 369.16,284.28 371.49,274.54 380.85,278.35 386.85,270.22 378.13,269.89 389.7,264.25 
		386.47,256.93 392.31,253.38 389.49,246.44 382.64,246.09 382.71,251.89 377.87,248.11 	"
        />
        <polygon
          className="st0"
          id="leaf-158"
          points="174.55,250.29 164,254.41 159.58,251.89 153.24,245.16 152.38,238.48 148.03,243.5 142.74,238.6 
		139.84,244.24 132.46,240.02 136.41,232.86 131.15,226.69 136.33,226.04 141.6,229.13 144.56,224 146.25,219.37 152.23,226.43 
		155.73,221 160.99,227.85 167.01,226.25 169.05,234.27 174.33,232.39 170.41,236.7 178.35,236.6 173.21,239.31 176.89,240.95 
		179.74,247.21 170.53,246.1 	"
        />
        <polygon
          className="st0"
          id="leaf-159"
          points="334.64,391.24 339.87,389.92 339.93,394.67 346.86,389.84 349.04,403.95 343.01,401.38 341.96,407.39 
		336.06,405.21 331.41,413.51 325.63,410.51 317.76,420.93 312.44,414.99 312.36,408.75 306.62,409.32 311.51,400.01 304.45,394.85 
		309.35,389.12 314.99,398.47 319.12,389 324.33,385.61 325.45,395.84 333.26,380.51 	"
        />
        <polyline
          className="st0"
          id="leaf-160"
          points="283.76,379.11 298.15,374.41 292.34,370.15 286.11,372.87 288.5,352.12 281.41,363.05 278.68,356.58 
		272.22,360.6 266.62,356 263.1,363.51 253.89,359.77 257.62,368.64 247.46,366.15 242.2,369.55 248.99,372.84 254.77,372.53 
		256.78,379.31 250.52,379.38 246.75,386.47 254.47,387.65 257.88,390.09 262.64,385.63 266.55,389.98 267.6,397.2 273,385.51 
		278.55,386.08 283.25,389.78 283.76,379.11 	"
        />
        <polygon
          className="st0"
          id="leaf-161"
          points="250.33,351.8 262.61,351.65 262.58,349.41 271.52,346.46 267.89,339.66 278.91,333.15 271.8,331.11 
		270.99,322.15 258.84,326.78 247.03,333.07 241.68,328.93 243.91,338.44 236.02,340.52 234.71,348.57 240.12,347.08 231.98,357.34 
		239.5,354.85 244.19,361.67 244.09,353.17 251.14,359.94 	"
        />
        <polygon
          className="st0"
          id="leaf-162"
          points="169.78,359.4 175,366.65 179.11,359.29 187.9,362.84 197.94,362.24 206.44,357.06 197.82,352.44 
		201.19,348.19 190.67,352.53 192.01,339.07 187.61,339.13 187.78,331.28 180.8,334.35 178.21,329.7 169.42,329.81 165.03,337.45 
		158.8,337.53 165.91,343.03 156.82,350.35 164.13,352.85 160.88,358.7 	"
        />
        <polygon
          className="st0"
          id="leaf-163"
          points="377.62,320.85 380.46,327.24 384.96,327.18 385.07,335.8 392.05,331.02 400.67,337.15 402.96,331.35 
		414.68,331.44 412.29,324.86 406.06,324.94 406.01,320.5 416.52,320.37 402.75,314.06 398.12,317.1 396.91,307.65 401.22,306.21 
		399.31,301.46 385.94,302.55 383.73,310.69 377.47,308.35 371.39,311.89 377.54,314.36 	"
        />
        <polygon
          className="st0"
          id="leaf-164"
          points="406.85,287.68 414.68,293.79 412.93,301.3 419.81,298.14 425.62,301.14 426.9,292.23 424.24,282.39 
		418.48,283.68 418.4,276.92 427.58,272.65 419.71,270.44 424.47,263.83 414.59,267.27 409.18,264.02 409.28,271.95 398.94,270.69 
		404.77,275.7 392.63,279.77 389.78,277.27 393.61,287.84 400.75,286.6 398.97,292.57 	"
        />
      </g>
      <g id="Leaves_3">
        <polygon
          className="st0"
          id="leaf-165"
          points="226.34,31.41 225.45,29.67 223.86,30.82 222.55,30.07 221.62,32.06 219.65,30.47 218.17,31.61 
		220.57,33.96 219.57,35.41 221.1,36.18 223.61,35.39 224.15,39.26 226.4,38.94 227.13,41.03 229.24,41.29 230.03,43.04 
		232.44,44.67 233.41,43.77 235.17,43.86 234.5,45.15 236.25,45.35 238.4,46.69 238.03,44.01 240.29,43.69 242.44,45.04 
		242.83,40.72 241.72,41.42 241.18,39.09 239.84,39.71 240.04,37.93 237.57,38.28 237.06,36.16 238.76,35.82 241.1,35.38 
		239.53,34.29 239.74,31.85 237.94,34.62 237.15,32.1 235.84,33.7 235.01,31.63 233.05,34.64 231.52,35.61 229.23,36.48 
		229.4,33.72 230.61,32.24 228.26,33.44 227.83,31.09 	"
        />
        <polygon
          className="st0"
          id="leaf-166"
          points="274.79,60.51 276.62,58.58 275.25,56.26 278.75,55.77 277,53.71 278.72,51.79 276.61,48.63 
		280.17,47.09 279.49,42.15 281.49,41.45 280.19,37.34 280.94,33.67 278.02,35.33 276.92,31.92 275.14,33.42 273.34,31.79 
		271.85,33.88 269.95,32.25 268.66,35.05 266.65,32.71 264.72,36.12 262.93,34.59 262.14,37.95 260.21,38.42 260.7,41.92 
		257.65,37.31 258.51,43.58 256.77,44.77 258.14,46.15 255.2,46.87 258.11,49.72 255.98,52.53 257.58,55.03 260.46,52.33 
		259.66,56.42 263.52,54 265.83,55.57 263.4,57.68 264.49,60.26 267.4,58.6 266.36,61.68 271.09,58.72 272.65,60.18 	"
        />
        <polygon
          className="st0"
          id="leaf-167"
          points="208.45,74.47 211.94,72.34 215.28,70.46 219.55,68.79 213.57,68.54 215.4,64.87 210.99,65.48 
		210.65,61.74 206.77,62.28 210.46,59.07 207.28,59.51 206.59,54.49 204.69,53.75 206.5,48.64 210.45,49.17 211.43,45.26 
		208.92,43.99 205.21,44.5 202.98,41.39 200,43.24 199.46,39.35 196.16,44.13 191.95,43.63 194.75,48.28 193.76,50.22 198.24,52.66 
		192.48,55.26 197.58,55.74 198.92,57.61 200.97,59.39 196.2,60.05 194.15,58.26 192.21,58.53 192.92,63.65 189.31,64.87 
		189.89,69.11 193.02,69.58 198.06,69.6 200.44,72.51 203.43,70.66 206.98,71.61 	"
        />
        <polygon
          className="st0"
          id="leaf-168"
          points="224.58,82.6 227.89,80.69 232.13,81.56 232.15,69.21 232.08,62.98 232.24,53.54 231.06,50.78 
		229.83,51.48 227.81,51.23 226.47,54.07 224.74,54.04 226.49,58.05 223.83,57.88 224.43,62.18 220.39,60.01 218.36,62.62 
		215.15,62.4 215.74,66.7 213.64,67.79 212.86,70.82 209.98,71.08 209.76,74.29 213.29,74.87 215.17,77.93 219.6,77.32 
		220.34,81.73 	"
        />
        <polygon
          className="st0"
          id="leaf-169"
          points="236.54,131.53 237.61,128.61 241.77,128.04 239.99,123.13 241.84,118.26 240.9,116.18 245.75,115.51 
		242.46,113.02 243.18,107.94 239.52,111.76 235.86,110.61 235.27,109.03 233.07,111.73 234.16,114.35 231.73,115.05 233.65,118.66 
		228.92,121.25 229.41,124.77 225.36,125.33 225.76,128.23 227.13,132.83 231.03,136.53 233.12,133.47 235.7,134.78 	"
        />
        <polygon
          className="st0"
          id="leaf-170"
          points="231.74,107.49 231.22,103.69 234.18,99.78 238.2,96.65 233.47,97.3 230.57,98.99 232.03,94.92 
		231.71,92.57 231.26,89.31 228.24,89.73 224.76,91.13 222.79,89.38 220.06,92.33 221.39,96.57 218.77,97.67 217.96,101.1 
		216.37,102.98 218.94,105.57 217.82,109.42 221.57,107.24 223.34,110.68 226.11,109.38 228.69,109.94 	"
        />
        <polygon
          className="st0"
          id="leaf-171"
          points="228.14,145.41 230.87,141.39 230.55,139.13 235.66,136.94 234.33,141.99 232.26,143.76 237.33,144.14 
		240.64,146.53 243.13,144.97 243.77,149.61 246.44,150.32 246.2,154.55 250.64,155.29 251.3,160.07 247.95,159.31 249.1,165.65 
		247.49,168.71 243.95,164.6 241.78,165.44 241.06,160.26 235.65,160.34 239.88,158.67 236.29,157.14 232.05,156.78 233.47,152.39 
		230.05,153.13 230.96,148.95 227.99,148.95 228.3,146.61 	"
        />
        <polygon
          className="st0"
          id="leaf-172"
          points="211.54,100.64 211.09,97.39 206.75,94.55 208.41,92.28 207.98,89.16 204,87.55 202.17,84.49 
		199.9,89.26 197.83,84.58 193.59,86.89 191.05,84.12 188.42,84.48 183.58,81.71 182.89,76.71 175.93,71.44 174.05,71.7 166.05,70 
		172.04,73.76 172.85,76.83 175.94,77.04 175.89,79.46 180.21,81.28 177.45,85.23 180.17,86.51 178.7,89.64 176.75,90.29 
		183.46,97.39 187.41,102.95 190.97,101.06 195.05,103.9 197.25,99.55 202.42,102.88 203.02,97.99 	"
        />
        <polygon
          className="st0"
          id="leaf-173"
          points="244.21,180.34 246.04,182.52 248.82,180.92 248.39,177.78 251.9,174.25 247.31,170.01 247.68,162.5 
		244.66,161.85 244.43,160.21 239.27,164.42 238.13,161.69 232.32,164.78 228.64,166.81 231.73,170.49 229.77,175.02 225.89,172.36 
		221.8,177.95 224.19,182.04 222.77,184.98 224.27,188.12 224.86,191.24 222.37,189.75 219.36,190.02 221.1,192.67 224.37,195.41 
		225.01,198.98 227.99,197.35 228.12,193.07 231.81,191.95 233.49,187.61 235.17,189.81 235.39,187.04 238.85,187.47 241.23,183.03 
		240.52,180.09 	"
        />
        <polygon
          className="st0"
          id="leaf-174"
          points="268.6,121.34 259.5,117.96 254.27,116.91 255.08,109.94 258.9,113.61 261.6,112.36 264.87,115.22 
		264.19,108.68 264.86,103.94 261.77,102.38 255.17,104.18 254.27,97.67 258.39,97.1 257.85,93.19 261.08,90.98 259.21,87.03 
		268.39,91.07 271.19,95.33 276.35,92.66 280.02,96.76 285.88,95.95 282.27,100.87 287.79,101.77 282.9,105.43 288.49,106.87 
		289,110.56 286.93,116.38 284.13,114.33 279.85,116.33 278.6,121.73 275.51,120.17 	"
        />
        <polygon
          className="st0"
          id="leaf-175"
          points="280.04,201.89 283.37,198.61 281.39,198 283.76,193.71 278.7,192.2 278.68,184.04 277.3,182.04 
		273.49,184.75 277.09,178.11 275.13,177.5 274.56,174.94 274.42,170.77 271.46,170.08 270.39,167.14 266.29,167.71 267.15,173.98 
		264.6,173.01 266.85,179.74 261.36,192.62 259.36,197.3 256.71,194.8 253.73,194.77 254.21,198.23 249.02,198.95 250.82,203.99 
		250.16,207.16 253.11,204.55 256.61,205.13 257.13,208.84 260.53,205.73 264.93,208.86 267.4,203.64 277.34,206.27 275.07,202.58 	
		"
        />
        <polygon
          className="st0"
          id="leaf-176"
          points="269.66,132.24 263.66,129.27 261.8,130.42 259.79,137.63 268.11,150.13 273.24,158.14 281.22,152.79 
		280.8,149.72 287.98,149.84 287.13,143.7 290.17,143.05 291.24,134.63 289.2,129.54 293.07,125.2 290.94,124.6 284.53,128.17 
		283.51,125.63 279.4,129.84 278.02,126.39 275.16,131.48 272.31,128.52 	"
        />
        <polygon
          className="st0"
          id="leaf-177"
          points="305.57,122.58 302.69,126.63 298.05,121.96 305.08,118.99 303.53,113.05 301.18,110.46 304.47,108.01 
		308.33,108.38 307.79,104.47 313.45,104.77 314.05,101.24 318.86,100.58 320.57,103.79 324.11,101.85 328.91,102.45 330.29,105.89 
		336.42,105.59 332.36,109.07 339.16,109.68 334.5,114 339.14,116.09 337.93,120.43 335.22,123.18 337.66,127.73 333.26,124.71 
		330.19,128.76 328.46,124.11 324.27,127.94 323.38,122.81 318.67,125.46 317.11,127.3 314.84,123.99 310.67,124.02 308.88,126.82 	
		"
        />
        <polygon
          className="st0"
          id="leaf-178"
          points="300.85,161.33 298.01,160.91 295.39,156.78 298.85,156.31 294.7,151.83 298.16,151.35 294.2,148.16 
		298.86,141.53 303.45,140.89 303.91,144.21 307.67,140.31 310.7,141.95 309.06,146.29 310.73,148.87 313.91,146.18 316.4,146.58 
		312.78,151.57 321.01,151.54 320.68,155.91 324.06,155.25 323.49,151.2 327.81,154.74 326.18,157.83 328.73,161.34 325.22,163.7 
		320.69,165.45 316.9,163.73 311.36,165.62 310.8,161.55 303.81,163.67 305.26,160.72 	"
        />
        <polygon
          className="st0"
          id="leaf-179"
          points="247.78,226.84 246.28,223.28 245.77,219.6 243.41,219.92 241.05,216.94 243.33,206.25 241.23,205.19 
		239.35,204.7 236.93,207.85 235.51,211.69 232.64,212.69 229.57,212.21 229.98,215.16 227.71,214.01 228.4,218.99 231.05,219.68 
		225.49,220.75 222.13,222.56 222.39,224.48 225.84,226.56 221.46,229.72 223.67,231.52 222.97,236.28 225.68,234.1 228.45,236.72 
		230.08,234.39 234.07,237.15 239.72,233.81 239.53,232.49 244.41,231.51 245.73,229.07 	"
        />
        <polygon
          className="st0"
          id="leaf-180"
          points="263.46,254.67 261.3,249.71 258.74,248.41 253.41,249.15 253.77,245.65 250.7,247.94 249.07,243.58 
		247.16,239.6 244.16,240.01 242.96,237.46 240.29,239.02 238.47,239.44 236.87,244.59 234.41,243.23 232.95,245.13 230.5,245.64 
		234.2,247.84 236.57,251.47 232.24,252.07 231.9,255.81 229.82,254.23 227.88,256.19 229.41,258.7 235.07,260.47 238.99,259.92 
		237.58,262.45 241.25,261.94 245.27,263.99 247.25,261.11 250.01,262.65 252.17,258.61 256.6,258.68 261.62,258.16 260.83,254.87 	
		"
        />
        <polygon
          className="st0"
          id="leaf-181"
          points="286.79,185.99 296.1,188.54 295.57,184.77 292.78,183.03 289.02,182.61 287.27,180.23 288.62,176.64 
		292.53,176.1 294.11,174.17 295.3,170.46 294.16,168.34 297.55,167.16 300.61,166.74 301.19,170.92 304.54,168.9 304.88,171.41 
		308.92,170.28 307.01,173.95 312.51,172.63 311.52,176.74 315.85,175.22 314.35,179.76 315.33,182.75 311.66,183.97 313.56,187.4 
		310.17,188.58 308.88,186.48 303.89,189.45 303.28,188.11 301.44,191.21 303.11,193.53 300.44,193.34 297.95,191.69 296.77,194.41 
		292.85,193.82 290.14,193.76 287.97,194.49 286.3,189.61 	"
        />
        <polygon
          className="st0"
          id="leaf-182"
          points="326.51,96.24 326.85,98.72 331.16,96.86 334.28,99.94 340.04,98.1 343.43,98.67 346.78,97.38 
		345.16,102.16 347.51,102.67 346.64,108.39 350.05,95.51 356.25,90.89 355.86,88.04 352.42,87.19 359.74,84.6 357.28,81.83 
		358.7,77.1 352.36,76.24 351.48,72.88 352.31,69.86 348.95,68.09 346.32,70.07 342.09,67.96 340.84,73.94 337.95,71.02 
		334.05,72.8 330.18,77.07 331.64,80.84 326.96,81.49 324.96,79.04 323.72,83.56 319.82,85.34 316.79,85.97 316.45,89.47 
		319.5,89.05 319.75,93.86 324.93,92.31 	"
        />
        <polygon
          className="st0"
          id="leaf-183"
          points="287.16,82.46 283.83,79.37 284.35,76.93 279.63,75.61 275.61,73.15 277.71,72.2 276.16,68.6 
		277.44,65.93 281.35,63.32 283.03,60.29 285.74,59.92 284.64,57.7 287.87,57.26 290.03,54.85 291.93,55.25 292.32,58.06 
		294.9,57.7 296.99,54.81 300.7,56.9 302.63,60.38 305.52,59.42 306.7,63.2 303.03,64.23 303.58,68.23 301.52,68.51 301.73,73.87 
		298.63,72.37 296.18,72.71 293.77,76.16 295.99,77.03 299.07,76.47 299.03,80.03 294.47,83.16 291.26,79.91 289.81,82.68 
		288.56,82.26 	"
        />
        <polygon
          className="st0"
          id="leaf-184"
          points="210.68,137.85 209.77,131.24 209.35,128.24 212.76,122.09 215.88,121.66 215.35,117.8 211.87,119.54 
		212.34,115.69 205.75,119.28 204.46,116.78 201.84,113.65 198.82,119.29 195.85,117.18 190.74,118.99 185.69,116.69 183.89,119.62 
		180.64,120.07 177.89,119.51 176.81,124.23 173.93,126.99 179.73,129.42 177.65,132.62 176.37,135.86 178.46,138.49 179.16,141.23 
		182.07,141.81 185.05,143.93 188.15,142.45 192.85,144.54 194.75,140.05 198.82,140.98 200.28,137.84 202.98,141.45 205.64,141.09 
		205,136.48 208.25,139.68 	"
        />
        <polygon
          className="st0"
          id="leaf-185"
          points="373.19,80.01 374.23,73.72 374.69,67.86 373.87,61.86 381.2,59.77 385.06,55.49 389.43,54.89 
		393.19,56.06 391.12,59.48 399.01,61.4 401.1,58.1 404.21,60.68 407.19,56.18 413.22,55.34 410.18,59.43 417.52,61.94 
		409.48,65.04 405.68,69.8 410.28,70.86 415.95,71.98 412.26,76.01 406.04,76.99 406.52,81.97 398.55,84.13 398.18,81.43 
		392.77,83.66 390.38,80.15 378.64,82.65 379.53,78.29 	"
        />
        <polygon
          className="st0"
          id="leaf-186"
          points="386.27,93.75 388.08,86.75 385.85,80.71 380.21,81.49 377.05,84.51 373.2,82.46 373.95,87.91 
		370.33,90.36 364.84,89.17 360.32,92.57 362.65,99.39 363,104.83 356.67,104.98 358.19,108.74 353.1,110.72 353.71,115.11 
		355.43,120.38 349.81,124.18 359.4,124.72 361.93,128.65 366.52,121.67 373.17,119.56 372.03,117.04 379.81,115.97 380.1,112.28 
		386.43,113.56 389.79,106.36 387.24,105.12 388.18,100.42 394.16,99.2 392.63,93.86 386,97.55 	"
        />
        <polygon
          className="st0"
          id="leaf-187"
          points="457.99,110.18 459.9,105.4 456.15,104.64 451.51,105.28 452.18,102.32 456.34,95.16 448.13,101.02 
		447.49,96.39 444.09,98.14 450.71,92.51 462.03,86.65 460.12,83.69 459.34,78.06 455.13,83.31 454.92,78.67 448.3,83.61 
		447.73,77.89 444.45,71.26 440.88,74.97 438.73,73.4 436.05,80.37 431.45,79.71 429.64,86.83 431.1,89.64 424.47,92.91 
		425.12,97.6 428.96,98.95 426.48,107.45 430.45,106.68 430.94,114.84 434.11,109.83 439.64,111 440.16,114.79 446.27,111.58 	"
        />
        <polygon
          className="st0"
          id="leaf-188"
          points="404.89,131.86 410.57,126.89 410.13,123.68 417.3,121 418.03,116.62 421.12,114.7 420.02,106.76 
		416.59,108.63 418.73,103.47 415.77,103.88 411.64,99.46 408.43,106.57 405.93,104.24 401.66,108.51 402.28,112.95 397.23,112.81 
		397.83,117.1 393.16,114.88 392.55,120.14 388.29,118.42 387.6,126.79 384.56,128.3 383.57,133.97 389.31,130.66 389.55,134.82 
		396.22,139.43 400.01,136.56 398.84,133.03 401.94,129.92 	"
        />
        <polygon
          className="st0"
          id="leaf-189"
          points="461.87,141.63 465.14,142.3 466.94,139.06 471.12,142.23 476.14,143.4 476.74,139.58 482.2,142.57 
		486.58,136.35 490.61,132.98 485.98,130.63 490.73,124.36 485.77,125.05 489.1,118.04 484.4,120.56 479.01,119.43 484.17,113.48 
		480.13,114.04 481.12,110.35 472.13,108.97 471.45,113.51 469.19,105.26 466.65,104.49 464.72,106.07 463.46,111.22 458.42,116.67 
		454.97,118.82 455.66,115.69 451.87,115.32 446.86,122.38 448.41,127.12 445.26,133.83 448.3,134.15 447.84,141.7 453.01,141.17 
		456.06,144.31 	"
        />
        <polygon
          className="st0"
          id="leaf-190"
          points="496.31,163.2 500.5,160.85 501.53,163.7 505.76,161.62 508.94,163.22 511.11,161.15 514.53,158.91 
		512.14,156.53 517.07,155.84 518.07,150.95 522.21,152.01 524.65,150.04 525.72,145.68 522.22,145.49 522.34,143.57 518.8,145.01 
		518,142.95 520,141.58 523.94,142.13 523.46,138.66 520.56,137.27 521.57,133.35 519.31,131.9 516.56,133.5 515.26,132.46 
		512.09,136.57 510.22,134.24 506.21,136.02 504.32,138.18 502.95,137.56 500.84,140 497.05,141.5 495.83,144.79 494.52,150.27 
		489.56,154.48 489.88,156.75 494.51,158.56 	"
        />
        <polygon
          className="st0"
          id="leaf-191"
          points="428.67,189.62 434.4,185.09 433.42,183.35 439.21,176.57 437.12,175.01 440.63,169.27 433.61,171.92 
		435.83,165.64 432.45,163.49 427.28,167.38 425.2,164.49 421.49,168.74 417.47,166.74 412.73,166.21 410.98,171.13 404.84,176.46 
		403.59,182.62 404.1,186.28 400.09,187.03 405.23,191.77 406.65,195.28 409.01,200.19 410.13,202.84 420.82,199.89 428.79,196.68 	
		"
        />
        <polygon
          className="st0"
          id="leaf-192"
          points="450.69,173.1 445.55,170.51 443.39,170.81 446.31,166.69 445.29,164.64 440.47,163.57 436.13,162.98 
		435.44,158.03 434.88,153.98 439.93,152.74 438.08,150.79 435.03,147.09 429,147.24 432.55,143.04 429.71,140.41 434.08,139.25 
		438.29,141.83 440.42,138.38 443.79,137.91 446.49,137.54 444.66,142.19 447.48,144.69 450.87,144.9 449.07,149.27 456.01,147.77 
		456.34,150.19 461.31,150.33 464.34,152.39 459.02,157.65 460.56,160.79 456.29,162.7 456.82,167.58 454.24,169.31 	"
        />
        <polygon
          className="st0"
          id="leaf-193"
          points="270.26,249.67 262.48,240.97 265.57,239.49 274.07,240.96 279.18,241.57 290.16,240.05 296.35,243.5 
		294.93,252.07 290.13,250.09 284.6,255.95 276.08,255.47 277.32,252.92 268.87,253.82 	"
        />
        <polygon
          className="st0"
          id="leaf-194"
          points="373.62,191.01 375.04,193.65 370.99,198.77 379.51,195.32 381.94,199.64 385.4,192.21 391.5,191.37 
		390.84,186.58 395.07,183.82 389.61,177.71 393.5,172.42 392.53,165.42 384.37,169.43 376,169.89 372.83,168.14 367.26,171.79 
		361.8,168.61 357.6,180.55 363.04,181.38 364.86,184.96 373.93,183.7 	"
        />
        <polygon
          className="st0"
          id="leaf-195"
          points="348.64,139.43 340.38,142.05 339.24,147.3 335.81,149.5 336.51,154.61 342.71,155.55 344.59,161.58 
		351.45,159.71 352.08,155.85 361.84,155.57 362.38,151.03 367.85,148.41 371.56,137.74 374.96,133.23 367.68,135.39 363.93,141.98 
		359.44,136.53 355.2,140.01 351.66,137.61 	"
        />
        <polygon
          className="st0"
          id="leaf-196"
          points="158.58,121.17 151.23,113.26 154.07,111.13 147.97,101.42 158.49,100.39 164.32,104.85 169.9,97.82 
		165.4,87.49 154.04,90.8 150.44,84.87 141.11,94.14 127.29,88.42 124.18,83.48 117.03,87.06 122.61,96.34 111.85,94.89 
		105.67,100.44 120.71,105.18 116.51,115.97 130.22,106.41 133.61,121.52 142.67,115.65 	"
        />
        <polygon
          className="st0"
          id="leaf-197"
          points="102.46,136.15 101.66,142.95 96.08,142.56 97.34,151.69 93.21,149.15 89.03,148.36 83.71,139.59 
		71.65,142.45 70.58,134.75 73.94,129.63 70.34,124.6 74.86,117.29 73.53,113.99 82.6,121.69 85.51,112.33 91.47,121.83 
		99.59,115.32 108.24,119.07 102.88,122.3 107.52,126.44 110.3,133.91 98.65,131.01 	"
        />
        <polygon
          className="st0"
          id="leaf-198"
          points="52.36,161.39 64.97,158.45 59.21,154.78 57.02,145.26 48.5,151.1 43.18,144.25 31.71,141.47 
		30.8,150.79 20.48,144.57 16.91,152.5 9.13,148.96 9.92,154.68 21.22,158.37 23.93,165.32 38.18,161.86 33.4,166.31 29.83,170.01 
		34.2,174.26 36.41,169.1 44.08,165.64 46.41,173.94 	"
        />
        <polygon
          className="st0"
          id="leaf-199"
          points="95.32,102.13 97.24,94.61 86.96,94.15 83.56,81.4 73.92,87.96 67.12,81.19 60.77,88.13 57.51,85 
		56.92,93.57 46.65,88.49 39.69,92.98 37.95,101.82 46.96,100.25 48.86,106.89 53.86,114.42 58.97,103.75 68.74,106.46 72.27,96.18 
		96.07,105.21 	"
        />
        <polygon
          className="st0"
          id="leaf-200"
          points="141.66,168.25 138.82,162.04 134.51,157.05 143.5,152.96 149.06,155.04 137.84,146.32 143.69,136.97 
		134.64,137.38 130.49,127.95 126.29,135.95 118.83,134.18 112.85,129.07 112.57,140.42 100.09,144.04 105.02,148.69 101.68,155.54 
		112.52,160.38 118.17,156.51 123.12,167.59 127.82,161.09 133.62,169.36 	"
        />
        <polygon
          className="st0"
          id="leaf-201"
          points="217.82,77.77 216.7,69.71 224.59,66.39 224.17,63.32 232.27,62.2 235.94,56.91 246.08,60.74 
		246.9,66.66 254.26,63.56 259.69,68.69 253.42,71.79 253.31,80.74 247.39,81.56 242.1,88.55 242.61,93.84 235.43,93.64 
		228.92,97.75 228.49,94.6 222.27,95.45 221.87,90.9 223.83,85.71 218.82,85.06 221.76,78.8 	"
        />
        <polygon
          className="st0"
          id="leaf-202"
          points="84.37,180.13 83.3,162.98 80.84,170.94 78.94,166.63 73.61,172.55 66.88,161.39 64.98,170.09 
		63.33,158.1 58.74,158.73 55.65,166.8 49.4,163.8 54.77,174.54 46.82,175.64 51.33,182.43 49.31,193.65 56.48,189.28 60.82,199.58 
		66.6,194.49 77.06,199.91 75.18,193.3 83.53,195.15 89.58,189.67 85.55,188.65 87.95,186.62 93.84,181.65 85.07,182.86 	"
        />
        <polygon
          className="st0"
          id="leaf-203"
          points="55.11,200.67 49.51,204.7 50.91,214.82 46.46,215.44 39.95,222.22 36.29,216.84 28.82,223.76 
		27.5,214.21 20.96,215.12 17.66,213.32 12.64,202.76 15.68,199.01 19.38,203.68 25.14,199.44 28.53,207.6 30.24,203.54 
		29.99,197.03 35.63,197.99 41.67,190.15 39.89,195.99 48.48,192.54 49.83,197.62 	"
        />
        <polygon
          className="st0"
          id="leaf-204"
          points="212.73,184.1 211.51,175.28 217.97,172.79 220.54,165.82 214.84,161.98 209.59,157.24 198.95,168.41 
		202.9,155.99 195.67,156.98 187.56,161.86 187.28,168.06 180.61,167 187.94,176.94 179.81,179.66 181.03,188.48 187.65,183.03 
		187.93,191.21 193.94,195.75 193.87,185.03 199.16,184.3 200.06,194.9 204.8,182.07 207.63,188.2 	"
        />
        <polygon
          className="st0"
          id="leaf-205"
          points="122.53,208.06 116.36,206.47 109.92,212.55 110.9,203.84 104.46,210.56 103.67,204.84 96.57,212.9 
		87.86,209 91.05,220.18 81.28,223.23 90.29,226.52 84.47,235.26 91.36,234.31 96.94,240.24 108.07,238.7 108.9,232.45 
		120.97,231.63 118.18,225.78 120.65,221.1 131.77,219.57 130.98,213.81 135.2,211.53 134.16,204.01 	"
        />
        <polygon
          className="st0"
          id="leaf-206"
          points="163.68,222.86 154.39,219.26 155.69,216.26 147.9,217.34 152.94,208.79 148.49,207.22 145.84,202.4 
		139.54,199.88 142.07,192.19 147.88,198.73 150.94,194.34 150.21,189.05 161.44,194.3 165.35,188.97 174.35,191.38 170.56,196.73 
		179.48,197.75 179.81,200.14 184.51,203.58 173.78,203.63 178.58,211.72 170.69,210.02 173.51,218.14 166.81,214.72 	"
        />
        <polygon
          className="st0"
          id="leaf-207"
          points="122.16,197.18 129.32,185.42 135.16,184.61 134.51,179.89 139.65,174.64 136.9,170.51 132.67,172.77 
		132.01,167.97 127.72,171.78 124.48,166.82 119.99,171.2 113.22,165.26 113.04,172.16 109.28,171.11 104.91,176.33 101.35,181.9 
		108.4,185.76 102.74,191.93 108.33,191.44 111.32,196.44 121.8,187.68 	"
        />
        <polygon
          className="st0"
          id="leaf-208"
          points="493.24,201.33 501.92,198.53 506.59,199.48 508.26,195.16 513.46,192.18 513.18,181.5 509.47,180.94 
		510.66,171.93 505.46,174.91 504.04,172.84 496.03,173.95 493.51,168.85 487.66,180.64 487.09,176.54 481.38,182.78 490.94,189.62 
		478.57,192.01 489.07,195.78 483.77,200.09 	"
        />
        <polygon
          className="st0"
          id="leaf-209"
          points="444.75,235.61 443.77,228.55 452.88,223.37 452.18,217.64 445.43,218.57 449.14,206.67 441.5,212.08 
		439.62,206.68 435.33,213.39 433.12,208.88 421,213.11 423.68,217.72 415.58,218.16 420.17,224.19 419.73,235.05 428.24,227.75 
		424.01,236.5 424.65,242.76 431.34,241.84 430.37,248.09 436.08,250.03 438.11,244.98 441.68,247.85 442.52,239.16 	"
        />
        <polygon
          className="st0"
          id="leaf-210"
          points="413.96,242.44 420.3,234.76 422.85,228.58 417.26,227.25 422.31,224.73 423.6,220.93 423.14,217.59 
		427,214.33 423.8,212.51 418.17,211.71 415.2,217.33 412,215.73 405.07,223.03 398.25,219.67 398,226.05 393.59,225.3 
		389.96,226.94 392.37,234.54 390.52,237.52 396.34,241.93 398.08,238.97 400.97,244.24 405.26,237.52 406.98,243.41 409.53,240.56 
			"
        />
        <polygon
          className="st0"
          id="leaf-211"
          points="507.25,236.99 514.62,230.61 518.69,230.05 519.61,236.69 520.54,243.39 524.29,240.87 528.15,249.48 
		531.13,241.93 536.27,243.67 535.5,238.09 540.12,236.01 545.59,228.78 542.57,224.67 536.43,223.14 536.97,214.81 531.91,221.77 
		526.85,220.67 527.12,216.17 533.12,214.45 529.73,212.47 521.8,218.02 517.54,213.05 517.27,219.76 510.27,213.64 510.29,220.28 
		506.69,221.67 508.03,216.81 501.46,217.72 502.43,224.71 497.99,226.46 503.82,228.31 501.49,234.01 505.8,233.42 	"
        />
        <polygon
          className="st0"
          id="leaf-212"
          points="515.68,217.25 517.36,213.23 524.46,211.36 523.76,206.32 528.28,203.47 527.43,197.34 523.34,195.17 
		522.49,189.04 526.09,187.69 525.55,183.78 520.74,184.44 518.73,178.92 515.49,183.61 509.65,183.3 510.67,190.68 507.07,188.79 
		509.77,197.04 506.85,195.28 507.97,203.41 503.66,205.09 507.93,211.19 504.99,214.72 511.16,215.2 	"
        />
        <polygon
          className="st0"
          id="leaf-213"
          points="492.37,250.93 498.65,238.25 502.83,237.68 502.4,244.82 509.05,245.08 509.75,250.18 515.53,247.97 
		513.92,256.46 522.5,255.27 522.08,260.76 515.76,258.33 516.89,266.5 512.7,264.66 512.06,273.72 506.91,270.65 515.63,282.44 
		511.86,285.09 511.31,292.25 507.47,286.64 502.67,285.41 503.1,278.97 495.55,273.88 496.1,269.38 485.88,260.33 484.82,252.68 
		491.45,257.91 489.71,247.05 	"
        />
        <polygon
          className="st0"
          id="leaf-214"
          points="478.36,294.92 484.16,295.77 484.96,301.57 491.83,301.56 492.44,305.97 497.46,308.34 500.09,313.65 
		502.63,308.1 508.09,308.29 511.91,302.8 504.53,296.26 506.91,290.97 498.53,292.13 502.4,285.93 501.1,279.97 496.45,277.07 
		492.57,271.22 488.15,274.91 489.43,284.18 482.3,282.33 483.07,287.89 474.83,286.43 472.4,293.62 477.76,290.52 	"
        />
        <polygon
          className="st0"
          id="leaf-215"
          points="459.71,284.74 464.77,280.74 470.95,283.19 472.29,276.63 479.32,274.47 478.36,267.52 485.22,264.21 
		486.74,259.04 485.61,256.6 482.11,250.23 486.34,248.23 479.71,246.55 471.51,255.48 469.93,250.97 464.41,248.66 460.95,253.15 
		454.91,249.97 449.99,255.38 445.99,253.81 439.55,258.47 443.23,261.22 439.99,261.66 443.87,269.22 441.44,272.15 448.76,277.28 
		448.88,281.58 454.52,277.9 452.83,284.75 	"
        />
        <polygon
          className="st0"
          id="leaf-216"
          points="182.58,237.5 186.85,230.53 187.42,224.6 194.27,229.77 198.66,223.05 199.81,231.4 208.96,226.41 
		214.47,224 212.44,230.45 217.01,232.74 214.98,237.24 221.06,238.95 215.62,243.83 210.65,248.51 198.25,247.03 189.04,246.44 
		181.36,245.97 175.88,237.1 	"
        />
        <polygon
          className="st0"
          id="leaf-217"
          points="139.73,227.47 141.2,222.75 146.64,225.21 149.03,221.67 153.25,223.35 155.62,229 161.29,225.74 
		167.55,224.88 170.88,229.02 175.97,226.19 170.23,234.69 170.24,240.53 171.42,243.26 163.2,241.51 155.36,238.6 146.86,240.58 
		137.56,238.67 142.36,234.93 134.23,231.95 141.54,230.95 	"
        />
        <polygon
          className="st0"
          id="leaf-218"
          points="64.2,275.3 61.75,269.09 57.05,269.73 56.33,264.52 50.78,266.62 49.62,258.27 59.01,256.97 
		59.14,254.03 65.18,251.6 58.3,249.89 57.85,246.61 50.58,251.76 48.36,249.14 38.36,253.71 37.74,258.85 29.45,256.54 29,262.94 
		23.26,263.73 28.61,270.48 37.69,270.02 38.07,276.61 47.8,273.94 48.33,277.78 55.55,281.9 	"
        />
        <polygon
          className="st0"
          id="leaf-219"
          points="92,257.2 94.57,248.83 88.7,246.75 95.82,244.44 91.67,241.29 83.37,237.07 82.54,242.56 
		73.69,242.45 77.52,248.54 69.95,249.58 70.87,256.19 64.87,257.02 64.46,263.66 71.94,263.96 78.54,261.71 85.24,262.12 
		96.38,265.8 102.63,262.9 108.36,258.66 118.42,254.08 109.77,251.87 111.27,248.48 107.32,251.16 103.7,249.52 98.83,254.66 
		96.95,252.59 	"
        />
        <polygon
          className="st0"
          id="leaf-220"
          points="469.57,313.24 475.31,314.72 473.14,319.08 480.88,321.8 481.98,329.76 477.01,330.44 478.92,340.82 
		475.11,341.35 471.62,346.39 467.47,345.69 460.92,358.76 459.95,351.8 451.09,349.98 457.4,344.3 447.97,338.38 451.65,335.72 
		446.61,328.57 453.03,329.2 447.68,317.28 454.58,309.23 455.65,315.16 464.27,307.89 463.63,316.08 	"
        />
        <polygon
          className="st0"
          id="leaf-221"
          points="373.69,349.22 379.52,347.41 379.09,355.32 385.02,350.57 394.71,354.68 393.44,345.48 399.85,342.06 
		396.2,339.78 402.47,333.85 395.9,326.65 402.68,318.88 397.65,315.52 401.85,312.91 399.53,308.93 386.36,320.88 383.36,307.36 
		376.31,313.15 368.2,309.46 362.01,312.34 358.63,308.07 356.95,316.08 362.09,322.08 359.84,329.61 364.62,333.04 362.22,335.87 
		359.87,337.2 360.94,344.91 369.94,340.37 	"
        />
        <polygon
          className="st0"
          id="leaf-222"
          points="433.14,386.63 438.64,389.95 443.04,385.26 452.91,390.62 450.74,386.12 459.07,383.05 464.96,382.23 
		467.45,374.2 466.85,369.84 455.51,373.93 456.21,367.59 459.22,365.01 450.81,358.97 446.93,366.47 444.26,361.06 439.15,367.54 
		432.12,367.08 433.85,374.4 429.51,376.08 423.17,378.76 432.97,380.17 427.43,383.58 436.76,383.25 	"
        />
        <polygon
          className="st0"
          id="leaf-223"
          points="391.76,397.58 396.02,393.63 405.43,395.69 413.21,392.93 410.5,389.94 413.05,376.14 406.3,370.71 
		404.29,378.79 399.21,378.53 400.58,388.43 394.34,378.01 387.83,376.14 385.63,382.81 378.17,377.48 368.45,380.14 372.87,387.76 
		370.18,392.63 377.74,393.51 376.73,401.82 383.89,396.26 383.74,407.33 	"
        />
        <polygon
          className="st0"
          id="leaf-224"
          points="113.1,289.55 114.01,284.36 117.33,283.17 118.91,287.11 123.21,284.17 123.87,288.96 130.63,289.47 
		129.37,292.9 124.15,293.62 124.03,297.98 130.15,298.58 132.76,304.91 126.73,305.75 122.81,303.57 118.04,306.95 113.53,305.76 
		106.18,312.74 108.85,305.87 99.58,314.74 98.97,310.31 93.2,309.48 92.61,305.22 96.85,302.82 91.18,294.93 94.46,294.65 
		94.63,289.39 92.76,288.92 94.22,286.37 100.99,287.6 102.89,281.06 106.32,286.32 109.88,284.75 	"
        />
        <polygon
          className="st0"
          id="leaf-225"
          points="238.98,336.52 244.69,330.1 251.14,334.84 263.93,330.25 252.03,322.87 242.43,324.2 233.34,327.82 
		227.14,321.87 226.01,313.73 214.53,322.72 209.07,314.72 206.85,322.9 200.87,321.95 205.65,331.35 199.38,333.11 192.89,337.56 
		197.15,344.81 203.68,342.28 208.15,347.29 212.93,342.63 222.91,349.1 219.89,355.14 231.49,356.2 231.15,342.33 240.77,349.49 
		236.62,339.36 	"
        />
        <polygon
          className="st0"
          id="leaf-226"
          points="230.91,278.12 229.5,267.94 223.63,264.38 220.31,255.29 215.02,259.89 211.93,257.73 208.25,262.3 
		199.76,256.56 192.37,260.44 186.6,259.95 193.12,265.87 192.72,273.02 199.87,270.26 202.05,275.3 209.4,273.69 210.66,282.78 
		214.65,277.43 221.15,281.33 220.17,274.27 	"
        />
        <polygon
          className="st0"
          id="leaf-227"
          points="430.14,361.98 435.45,354.45 441.47,353.62 437.27,346.97 442.25,341.8 433.7,341.77 433.94,335.6 
		441.05,333.08 445.56,326.11 443.65,320.23 433.98,327.93 433.75,314.37 429.39,316.95 425.6,313.31 420.23,320.4 414.82,316.11 
		405.83,320.86 412.18,322.39 399.24,327.03 396.91,324.94 389.1,328.21 391.26,332.73 388.09,335.14 394.34,337.57 399.08,340.64 
		395.59,346.6 400.33,349.67 405.51,345.44 406.35,354.75 413.9,349.1 414.87,357.73 423.13,357.25 423.71,366.15 	"
        />
        <polygon
          className="st0"
          id="leaf-228"
          points="72.78,359.83 74.74,353.42 71.23,348.65 70.01,339.84 77.24,338.84 78.46,332.77 72.87,333.55 
		70.26,327.31 66.42,334.44 62.32,331.7 54.42,339.58 54.48,331.9 48.36,336.93 42.13,336.24 43.47,345.92 38.09,348.19 38.89,354 
		47.09,353.08 48.07,360.18 54.57,354.9 57.3,361.97 61.7,357.38 65.47,360.84 	"
        />
        <polygon
          className="st0"
          id="leaf-229"
          points="69.81,298.06 67.81,304.86 66.08,304.07 61.71,311.89 59.28,310.31 52.69,311.67 54.21,308.51 
		43.3,303.39 44.01,297.84 47.99,301.07 46.08,291.52 50.6,295.46 55.77,288.11 59.14,292.22 65.96,285.09 64.87,292.16 
		74.24,291.45 68.97,295.13 75.33,299.4 	"
        />
        <polygon
          className="st0"
          id="leaf-230"
          points="86.7,331.19 80.3,327.51 76.4,328.05 71.36,324.62 73.82,324.28 71.56,321.79 76.52,318.75 
		71.18,313.74 75.3,309.93 73.42,306.51 80.9,308.42 85.27,304.87 89.05,309.8 94.45,311.56 92.56,317.12 97.91,316.38 
		99.65,320.41 102.45,317.23 103.71,314.17 105.69,321.64 102.27,325.5 97.48,326.02 94.23,332.36 92.81,330.64 90.4,336.71 	"
        />
        <polygon
          className="st0"
          id="leaf-231"
          points="175.67,310.68 172.8,304.62 169,302.69 164.92,300.1 160.42,299.61 159.36,291.96 160.37,285.89 
		166.9,288.83 164.84,281.93 169.2,277.24 170.65,283.72 175.02,276.44 177.81,281.81 179.02,283.86 189.33,279.66 189.71,282.39 
		184.45,287.57 193.4,289.93 193.21,297.91 201.15,298.99 200.12,303.59 192.75,304.42 194.79,308.03 187.15,307.07 186.14,311.3 
		181.96,307.79 	"
        />
        <polygon
          className="st0"
          id="leaf-232"
          points="181.77,342.68 187.62,336.68 185.83,335.81 193.29,330.69 190.38,327.14 190.26,318.5 190.85,313.03 
		182.84,314.14 180.33,312.1 174.46,315.3 167.95,312.49 165.64,315.96 156.64,317.95 163.38,321.04 158.63,325.65 158.29,329.87 
		160.33,335.24 168.96,329.23 167.95,335.31 171.56,341.31 173.87,336.53 175.5,345.63 178.7,337.91 	"
        />
        <polygon
          className="st0"
          id="leaf-233"
          points="97.18,385.75 92.56,381.34 89.37,377.44 85.36,382.69 79.92,378.75 74.64,382.37 68.7,371.87 
		70.43,365.85 74.91,368.6 72.53,363.63 78.68,362.78 79.81,356.13 88.15,356.42 87.09,352.23 94.89,351.15 98.4,357.41 
		101.74,350.2 105.65,359.3 99.91,366.59 108.84,366.68 100.19,372.09 107.97,376.08 100.95,375.84 101.51,379.86 96.4,380.08 	"
        />
        <polygon
          className="st0"
          id="leaf-234"
          points="206.13,419.16 214.04,427.02 218.81,420.03 225.42,422.46 229.88,415.87 240.43,414.42 241.48,422.03 
		252.76,406.44 249.64,399.02 255.52,392.63 246.26,399.48 242.63,389.33 236.65,397.92 231.01,387.36 222.52,390.02 219.31,384.79 
		209.45,384.07 210.86,394.32 199.86,395.24 204.85,401.12 197.77,403.89 198.82,411.51 207.49,409.42 	"
        />
        <polygon
          className="st0"
          id="leaf-235"
          points="337.2,297.7 344.66,304.12 343.65,296.81 354.19,295.35 348.06,292.02 362.41,287.86 357.6,269.51 
		348.49,281.81 347.44,275.69 337.67,277.34 339.56,284.54 330.23,281.35 325.15,290.4 316.69,283.22 310.89,292.37 303.53,298.17 
		309.08,301.59 302.34,311.18 308.03,313.69 309.25,322.58 313.73,312.9 325.94,317.77 331.06,313.48 325.09,302.96 335.31,307.81 	
		"
        />
        <polygon
          className="st0"
          id="leaf-236"
          points="147.48,80.87 149,91.89 144.17,87.03 132.38,86.48 130.9,75.77 123.41,78.08 115.4,74.79 
		105.83,84.64 107.28,72.53 102.75,62.35 112.21,65.16 123.72,61.5 131.61,67.76 137,59.67 142.85,66.21 149.23,61.53 150.16,68.27 
		156.56,64.31 157.31,69.78 147.67,74.72 153.1,80.09 	"
        />
        <polygon
          className="st0"
          id="leaf-237"
          points="179.88,407.26 187.28,400.43 179.09,401.56 188.75,390.08 179.35,387.02 182.4,377.04 170.56,374.8 
		157.46,378.81 162.59,387.53 152.24,385.69 150.2,381.49 142.32,389.97 135.47,387.65 136.89,397.97 129.56,397.34 127.9,406.37 
		133.43,405.6 130.42,414.09 138.67,410.78 139.01,423.79 146.84,422.7 147.99,415.29 158.97,423.87 160.3,412.5 167.45,422.69 
		171.59,410.22 	"
        />
        <polygon
          className="st0"
          id="leaf-238"
          points="208.81,303.07 212.93,307.54 207.57,311.57 212.99,314.32 217.78,312.55 221.05,317.15 223.4,313.54 
		236.97,319.99 236.34,315.47 246.3,320.8 249.94,313.81 238.06,307.13 245.92,299.04 243.53,290.8 238.16,298.36 235.66,289.72 
		229.96,289.2 226.48,296.25 217.17,287.51 214.38,292.66 205.8,289.08 200.89,282.26 196.12,285.62 197.42,295 199.58,301.28 
		203.76,298.29 	"
        />
        <polygon
          className="st0"
          id="leaf-239"
          points="346.36,254.49 333.5,242.51 327.95,248.99 327.47,245.54 317.28,247.17 321.77,251.52 318.98,259.47 
		327.36,261.22 326.68,271.15 332.94,270.29 341.93,281.27 343.02,271.31 352.79,273.92 357.73,265.1 349.04,265.87 359.81,258.83 
		355.69,251.97 361.04,247.72 357.37,241.19 350.53,241.69 351.33,247.44 346.05,244.29 	"
        />
        <polygon
          className="st0"
          id="leaf-240"
          points="157.58,260.9 147.62,266.31 142.93,264.36 135.8,258.47 134.11,251.96 130.42,257.48 124.56,253.28 
		122.39,259.24 114.54,255.97 117.56,248.37 111.57,242.91 116.63,241.61 122.25,244.03 124.54,238.56 125.65,233.76 132.46,240.02 
		135.25,234.2 141.33,240.33 147.1,238 150.12,245.7 155.13,243.18 151.78,247.94 159.64,246.85 154.88,250.18 158.74,251.35 
		162.34,257.2 153.07,257.25 	"
        />
        <polygon
          className="st0"
          id="leaf-241"
          points="341.92,366.08 346.95,364.12 347.6,368.83 353.88,363.17 357.79,376.9 351.49,375.1 351.2,381.19 
		345.07,379.77 341.5,388.58 335.39,386.33 328.88,397.65 322.86,392.42 322.01,386.23 316.38,387.52 320.07,377.68 312.42,373.44 
		316.57,367.14 323.33,375.71 326.24,365.8 330.99,361.79 333.38,371.8 339.21,355.61 	"
        />
        <polygon
          className="st0"
          id="leaf-242"
          points="259.34,361.42 271.51,359.73 271.2,357.51 279.7,353.47 275.25,347.18 285.37,339.34 278.06,338.21 
		276.13,329.42 264.66,335.53 253.73,343.24 247.91,339.8 251.3,348.96 243.74,352.01 243.44,360.16 248.62,358.01 241.83,369.2 
		248.98,365.79 254.48,371.98 253.32,363.56 261.16,369.39 	"
        />
        <polygon
          className="st0"
          id="leaf-243"
          points="205.36,378.46 211.45,385.01 214.61,377.19 223.77,379.61 233.66,377.76 241.44,371.56 232.31,368.06 
		235.12,363.41 225.24,369.04 224.88,355.52 220.53,356.12 219.71,348.32 213.17,352.23 210.02,347.94 201.31,349.15 197.91,357.28 
		191.74,358.13 199.48,362.7 191.38,371.1 198.94,372.67 196.45,378.88 	"
        />
        <polygon
          className="st0"
          id="leaf-244"
          points="394.5,311.12 398.12,317.1 402.58,316.48 403.76,325.02 410.09,319.41 419.41,324.41 420.96,318.37 
		432.6,317 429.4,310.77 423.23,311.62 422.62,307.23 433.04,305.79 418.59,301.24 414.37,304.84 411.99,295.61 416.09,293.65 
		413.6,289.17 400.47,291.92 399.29,300.27 392.79,298.73 387.2,303 393.61,304.69 	"
        />
        <polygon
          className="st0"
          id="leaf-245"
          points="406.63,278.26 415.17,283.34 414.36,291.01 420.8,287.02 426.94,289.27 427.09,280.27 423.23,270.84 
		417.68,272.84 416.75,266.14 425.33,260.76 417.24,259.55 421.14,252.4 411.77,257.04 405.99,254.49 407.08,262.35 396.66,262.39 
		403.07,266.63 391.54,272.19 388.4,270.06 393.51,280.07 400.44,277.95 399.43,284.1 	"
        />
        <polygon
          className="st0"
          id="leaf-246"
          points="30.47,209.57 28.27,213.33 20.98,214.21 20.98,219.29 16.12,221.5 16.12,227.69 19.87,230.4 
		19.87,236.58 16.12,237.43 16.12,241.38 20.98,241.38 22.21,247.13 26.06,242.93 31.8,244.03 31.8,236.58 35.11,238.95 
		33.57,230.4 36.22,232.55 36.22,224.34 40.72,223.27 37.32,216.64 40.72,213.55 34.67,212.22 	"
        />
      </g>
    </svg>
  );
}
