import React, { useContext } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../images/aki_logo512.png";
import { TbLogout } from "react-icons/tb";
import { BsPersonCircle } from "react-icons/bs";
import { AppContext } from "../App";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import "./Header.css";

export default function Header() {
  // Accessing context variables, mainly the login data
  const { user, setUser } = useContext(AppContext);
  const navigator = useNavigate();

  function handleLogout() {
    signOut(auth)
      .then(() => {
        // Succesful sign out
        sessionStorage.clear();
        setUser(undefined);
        navigator("/", { replace: true });
      })
      .catch((error) => {
        alert("Sign out error");
        console.log(error.code, error.message);
      });
  }

  return (
    <Navbar id="navbar" collapseOnSelect expand="lg" className="sticky-top">
      <Container fluid>
        <Navbar.Brand
          className="d-flex m-0 p-0"
          href={user === undefined ? "/" : "/dashboard"}
        >
          <img
            alt="Aki Logo"
            src={Logo}
            style={{ height: "8vh" }}
            className="mx-3"
          />
          <h2 className="h-100 mx-2 my-auto text-white">
            <span>Aki</span>
            <span className="d-none d-lg-inline"> - Japanese</span>
            <span className="d-none d-xl-inline"> Language Suite</span>
          </h2>
        </Navbar.Brand>
        {!(user === undefined) ? (
          <>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              id="navbar-items"
              className="me-1"
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link className="text-white py-3 ms-3" href="/kanji">
                  <h5 className="my-auto">Kanji</h5>
                </Nav.Link>
                <Nav.Link className="text-white py-3 ms-3" to="/vocabulary">
                  <h5 className="my-auto">Vocabulary</h5>
                </Nav.Link>
                <Nav.Link className="text-white py-3 ms-3" to="/kanji">
                  <h5 className="my-auto">Grammar</h5>
                </Nav.Link>
                <Nav.Link className="text-white py-3 ms-3" to="/vocabulary">
                  <h5 className="my-auto">Reading</h5>
                </Nav.Link>
                <Nav.Link
                  className="text-white py-3 ms-3 d-lg-none"
                  to="/vocabulary"
                >
                  <h5 className="my-auto">Profile</h5>
                </Nav.Link>
                <Nav.Link
                  className="text-white py-3 ms-3 d-lg-none"
                  to="/vocabulary"
                >
                  <h5 className="my-auto">Log out</h5>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Nav.Link
              to="/"
              onClick={handleLogout}
              className="mx-3 d-none d-lg-inline"
            >
              <BsPersonCircle size="4vh" className="mx-2 text-white" />
              <TbLogout
                size="5vh"
                className="mx-2 text-white"
                onClick={() => handleLogout()}
              />
            </Nav.Link>
          </>
        ) : (
          <></>
        )}
      </Container>
    </Navbar>
  );
}
