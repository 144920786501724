import React from "react";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  if (location.pathname === "/dashboard") {
    return (
      <div id="footer">
        <footer className="text-center light-text w-100 bg-light" id="footer">
          <small>Copyright &copy; Aki Languages 2023</small>
        </footer>
      </div>
    );
  } else {
    return null;
  }
}
