import React, { useState } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  ProgressBar,
} from "react-bootstrap";
import "./Review.css";
import { toKana } from "wanakana";
// import MapleLeaf from "../svg/MapleLeaf";

export default function Review() {
  const [answer, setAnswer] = useState("");

  return (
    <Container fluid style={{ height: "90vh" }} className="d-flex flex-column">
      <Row>
        <Col className="text-center py-5" id="item-card">
          <div className="w-75 mx-auto pt-3" id="item-text">
            おめでとうございました
          </div>
          <div className="mt-2" id="item-subtext">
            Vocabulary reading
          </div>
        </Col>
      </Row>
      <Row className="mt-auto">
        <Col className="p-0">
          <Form>
            <Form.Group className="w-100 text-center align-items-center">
              <Form.Control
                className="mx-auto my-5 w-100 rounded-0 text-center"
                id="input-bar"
                type="text"
                placeholder="Answer"
                value={answer}
                autoFocus
                autocapitalize="none"
                onChange={(e) =>
                  setAnswer(toKana(e.target.value, { IMEMode: true }))
                }
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <ProgressBar now={87} className="m-0 p-0 rounded-0" />
      </Row>
      <Row className="mt-0">
        <Col className="p-0">
          <Button
            className="btn-lg rounded-0 px-3 py-4 container-fluid diff-btns"
            id="btn-hard"
          >
            HARD
          </Button>
        </Col>
        <Col className="p-0">
          <Button
            className="btn-lg rounded-0 px-3 py-4 container-fluid diff-btns"
            id="btn-med"
          >
            NORMAL
          </Button>
        </Col>
        <Col className="p-0">
          <Button
            className="btn-lg rounded-0 px-3 py-4 container-fluid diff-btns"
            id="btn-easy"
          >
            EASY
          </Button>
        </Col>
        <Col className="p-0">
          <Button
            className="btn-lg rounded-0 px-3 py-4 container-fluid diff-btns"
            id="btn-very-e"
          >
            TOO EASY
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
