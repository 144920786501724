import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  Container,
  Col,
  Row,
  Card,
  Image,
} from "react-bootstrap";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { AppContext } from "../App";
import "./Login.css";
import TreeImg from "../images/Tree.png";

export default function Login() {
  // Accessing context variables, mainly the login data
  const { user, setUser } = useContext(AppContext);

  // Auto-update variables to store the login information to be sent to the API
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Firebase call for an already registered user
  async function login(e) {
    // Does not post the form data anywhere
    e.preventDefault();

    // Sends the request and stores necessary user information
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in succesfully
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            user: userCredential.user,
            email: userCredential.email,
          })
        );
        setUser({
          ...user,
          user: userCredential.user,
          email: userCredential.email,
        });
      })
      .catch((error) => {
        if (error.code === "auth/invalid-login-credentials") {
          alert(
            "Invalid login credentials, verify your email/password and try again"
          );
        } else {
          alert("Login error unrelated to your credentials");
          console.log(error.code, error.message);
        }
      });
  }

  return (
    <Container fluid id="login-bg">
      <Row style={{ height: "10vh" }} />
      <Row className="mx-4">
        <Col lg="2" md="1" sm="0" xs="0"></Col>
        <Col
          lg="4"
          md="5"
          sm="12"
          xs="12"
          className="d-flex align-items-center justify-content-center px-0"
        >
          <Card className="w-100 px-4 py-5" id="login-card">
            <div className="h1 pt-4 pb-2 text-center text-light">
              Welcome back!
            </div>
            <Form onSubmit={login} className="mx-4">
              <Form.Group size="lg" controlId="username">
                <Form.Label className="text-light h5 mt-3">Email</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={email}
                  placeholder="example@email.com"
                  className="shadow-none rounded-5 text-field"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" className="mt-2" controlId="password">
                <Form.Label className="text-light h5 mt-2">Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  placeholder="Your password"
                  className="shadow-none rounded-5 text-field"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <div className="mt-5 mb-3 text-center">
                <Button
                  className="mx-3 px-3 mb-2 btn-lg"
                  id="login-button"
                  type="submit"
                  disabled={email.length === 0 || password.length === 0}
                >
                  LOG IN
                </Button>
                <div>
                  <a className="text-light" href="/signup">
                    Sign up here
                  </a>
                </div>
              </div>
            </Form>
          </Card>
        </Col>
        <Col lg="4" md="5" sm="12" xs="12" className="px-0">
          <Card className="w-100 h-100" id="tree-card">
            <Image fluid id="tree-bg" className="px-5 pt-5" src={TreeImg} />
            <div
              id="tree-txt-bg"
              className="my-0 px-2 py-4 h-100 text-center text-light d-flex align-items-center justify-content-center"
            >
              <div>
                <div className="h5 mb-2">日本語を勉強し、木を植えよう</div>
                <div className="h5">Study Japanese, plant trees</div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg="2" md="1" sm="0" xs="0"></Col>
      </Row>
      <Row style={{ height: "10vh" }} />
    </Container>
  );
}
