import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Container,
  Col,
  Row,
  Card,
  Image,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { AppContext } from "../App";
import "./SignUp.css";
import TreeImg from "../images/Tree.png";

export default function SignUp() {
  // Accessing context variables, mainly the login data
  const { user, setUser } = useContext(AppContext);
  const navigator = useNavigate();

  // Auto-update variables to store the login information to be sent to the API
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");

  // Regex for email and password formats
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/;

  // Firebase call to register a user
  async function signup(e) {
    // Does not post the form data anywhere
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // As the user is created, it is also logged in
        const user = userCredential.user;
        setUser(user.email);
      })
      .catch((error) => {
        alert("Sign up error");
        console.log(error.code, error.message);
      });
  }

  // Does not require login if an acive session is found
  useEffect(() => {
    if (user !== undefined) {
      navigator("/", { replace: true });
    }
  }, [user, navigator]);

  return (
    <Container fluid id="signup-bg">
      <Row style={{ height: "10vh" }} />
      <Row className="mx-4">
        <Col lg="2" md="1" sm="0" xs="0"></Col>
        <Col
          lg="4"
          md="5"
          sm="12"
          xs="12"
          className="d-flex align-items-center justify-content-center px-0"
        >
          <Card className="w-100 px-4 py-5" id="login-card">
            <div className="h2 pt-4 pb-2 text-center text-light">
              Nice to meet you!
            </div>
            <Form onSubmit={signup} className="mx-4">
              <Form.Group size="lg" controlId="username">
                <Form.Label className="text-light h5 mt-3">
                  Your email
                </Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={email}
                  placeholder="example@email.com"
                  className="shadow-none rounded-5 text-field"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" className="mt-2" controlId="password">
                <Form.Label className="text-light h5 mt-2">
                  Choose a password
                </Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="password"
                    value={password}
                    className="shadow-none text-field w-50"
                    placeholder="Password"
                    id="pwd-field-1"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Form.Control
                    type="password"
                    value={passwordConf}
                    className="shadow-none text-field w-50"
                    id="pwd-field-2"
                    placeholder="Confirmation"
                    onChange={(e) => setPasswordConf(e.target.value)}
                  />
                </div>
                <Form.Label
                  className="text-light mt-2 text-center w-100"
                  id="password-label"
                >
                  Include at least 8 letters, numbers, and symbols
                </Form.Label>
              </Form.Group>
              <div className="mt-5 mb-3 text-center">
                <Button
                  className="mx-3 px-3 mb-2 btn-lg"
                  id="login-button"
                  type="submit"
                  disabled={
                    email.length === 0 ||
                    password.length === 0 ||
                    password !== passwordConf ||
                    !emailRegex.test(email) ||
                    !passwordRegex.test(password)
                  }
                >
                  SIGN UP
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
        <Col lg="4" md="5" sm="12" xs="12" className="px-0">
          <Card className="w-100 h-100" id="tree-card">
            <Image fluid id="tree-bg" className="px-5 pt-5" src={TreeImg} />
            <div
              id="tree-txt-bg"
              className="my-0 px-2 py-4 h-100 text-center text-light d-flex align-items-center justify-content-center"
            >
              <div>
                <div className="h5 mb-2">日本語を勉強し、木を植えよう</div>
                <div className="h5">Study Japanese, plant trees</div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg="2" md="1" sm="0" xs="0"></Col>
      </Row>
      <Row style={{ height: "10vh" }} />
    </Container>
  );
}
