import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import React, { useState, createContext, useEffect } from "react";
import { Header, Login, Dashboard, Footer, SignUp, Review } from "./components";

import "./App.css";

// Application level context variables to be shared across levels
export const AppContext = createContext([[], () => {}]);

function App() {
  // Auto-login functionality
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    if (sessionStorage.getItem("user") && user === undefined) {
      setUser(JSON.parse(sessionStorage.getItem("user")));
    }
  }, [user, setUser]);

  return (
    <BrowserRouter>
      <AppContext.Provider value={{ user, setUser }}>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              user === undefined ? <Login /> : <Navigate to="/dashboard" />
            }
          />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/dashboard"
            element={user !== undefined ? <Dashboard /> : <Navigate to="/" />}
          />
          <Route path="/review" element={<Review />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
