import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Aki languages app configuration generated
const firebaseConfig = {
  apiKey: "AIzaSyDiyPxF8BX7L3MXydhFMIIZ9Z0Q0e1BEiY",
  authDomain: "aki-languages.firebaseapp.com",
  projectId: "aki-languages",
  storageBucket: "aki-languages.appspot.com",
  messagingSenderId: "33244396652",
  appId: "1:33244396652:web:0526a041b9675f806b2c49",
  measurementId: "G-9K00SFC93N",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
export { firebaseApp, auth };
