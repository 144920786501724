import React from "react";
import { Container, Col, Row, Button, Card } from "react-bootstrap";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { TbClockUp } from "react-icons/tb";
import Tree from "../svg/Tree";

export default function Dashboard() {
  const navigator = useNavigate();
  function loadReviews() {
    navigator("/review", { replace: true });
  }

  return (
    <Container fluid>
      <Row className="">
        <Col lg="8" md="12" className="my-5">
          <Row className="mx-2 d-flex">
            <Col md="4" sm="6" xs="6" className="text-center">
              <Button
                id="study_button"
                className="btn-lg w-75 py-4 px-2 mx-2 top_buttons"
              >
                STUDY
              </Button>
            </Col>
            <Col md="4" sm="6" xs="6" className="text-center">
              <Button
                id="review_button"
                className="btn-lg w-75 py-4 px-2 mx-2 top_buttons"
                onClick={loadReviews}
              >
                REVIEW
              </Button>
            </Col>
            <Col
              md="3"
              sm="0"
              className="d-none d-md-inline align-items-center p-0"
            >
              <div className="text-center">
                <div className="h5 mt-3">
                  <b>Next review</b>
                </div>
                <div className="h5 mt-2 mb-0">
                  <TbClockUp size="1em" className="mb-1 me-3" />
                  2h 45m
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mx-5 mt-5">
            <Col className="overflow-hidden rounded-4 items-table">
              <Row>
                <div className="py-3 px-4 items-label">Kana progress</div>
              </Row>
              <Row className="text-center d-flex">
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>ほ</div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mx-5 mt-5">
            <Col className="overflow-hidden rounded-4 items-table">
              <Row>
                <div className="py-3 px-4 items-label">Kanji progress</div>
              </Row>
              <Row className="text-center d-flex">
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
                <Col md="1" sm="2" xs="3" className="p-3 char-card">
                  <div>食</div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mx-5 mt-5">
            <Col className="overflow-hidden rounded-4 items-table">
              <Row>
                <div className="py-3 px-4 items-label">Vocabulary progress</div>
              </Row>
              <Row className="text-center d-flex items-row">
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  おめでとうございます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
                <Col
                  md="3"
                  sm="4"
                  xs="6"
                  className="p-3 text-truncate char-card"
                >
                  励ます
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg="4" md="12" className="my-5 text-center">
          <div className="h2 w-100 text-center mt-3 mb-5" id="progress-title">
            Your progress tree
          </div>
          <Tree id="progress-tree" scale={0.6} />
        </Col>
      </Row>
    </Container>
  );
}
